import {
  WabaTemplateCategory,
  WhatsappButton,
  WhatsappButtonType,
} from "entities/domain/whatsapp-template";
import { isValidPhoneNumber } from "react-phone-number-input";

const containsOnlyLowerCaseLettersAndUndescoresAndNumbers = (
  name: string
): boolean => {
  return /^[a-z_0-9]+$/.test(name);
};

export const isValidUrl = (url_string: string): boolean => {
  try {
    const url = new URL(url_string);
    return url.protocol === "http:" || url.protocol === "https:";
  } catch (_) {
    return false;
  }
};

const containsEmojis = (text: string): boolean => {
  return /[\u{1F600}-\u{1F6FF}]/u.test(text);
};

/**
 *
 * Name is required at all times
 * Category is required at all times
 * footerText is optional at all times
 * If bodyText is present then headerText is optional
 * If bodyText is absent then headerText is required
 * File is optional but when it's present then both headerText and bodyText are optional
 * File supports only videos in mp4, images in jpeg/jpg/png and pdfs
 * Maximum file size is 5 MB
 *
 * @param name
 * @param category
 * @param headerText
 * @param bodyText
 * @param footerText
 * @param file
 */
export const validateWhatsappTempalte = (
  name: string,
  category: WabaTemplateCategory,
  headerText: string,
  bodyText: string,
  file: File | null,
  footerText: string,
  buttons: WhatsappButton[]
): string[] => {
  const errors: string[] = [];

  if (!name) {
    errors.push("Name is required");
  }

  if (!containsOnlyLowerCaseLettersAndUndescoresAndNumbers(name)) {
    errors.push("Name should contain only lowercase letters and underscores");
  }

  if (!category) {
    errors.push("Category is required");
  }

  if (!bodyText) {
    errors.push("Body is required");
  } else if (bodyText.length >= 1000) {
    errors.push(
      "To ensure messaging success, we’d recommend keeping this template message to maximum 1,000 characters. The length of this message could exceed the max character limit once the custom fields are replaced with the actual values as per WhatsApp policy"
    );
  }

  if (file) {
    const allowedFileTypes = [
      "image/jpeg",
      "image/jpg",
      "image/png",
      "video/mp4",
      "application/pdf",
    ];

    const fileSizeByType = {
      "image/jpeg": 5 * 1024 * 1024,
      "image/jpg": 5 * 1024 * 1024,
      "image/png": 5 * 1024 * 1024,
      "video/mp4": 16 * 1024 * 1024,
      "application/pdf": 100 * 1024 * 1024,
    };

    if (!allowedFileTypes.includes(file.type)) {
      errors.push("File type is not supported");
    }

    if (file.size > fileSizeByType[file.type as keyof typeof fileSizeByType]) {
      errors.push("File size is too large");
    }
  }

  if (headerText && containsEmojis(headerText)) {
    errors.push("Header text cannot contain emojis");
  }

  if (category === WabaTemplateCategory.MARKETING && !footerText) {
    errors.push(
      "Footer is required for marketing templates e.g. 'Reply UNSUB to unsubscribe'"
    );
  }

  if (footerText && containsEmojis(footerText)) {
    errors.push("Footer text cannot contain emojis");
  }

  if (buttons.length > 0) {
    if (buttons.length > 3) {
      errors.push("You can have a maximum of 3 buttons");
    }

    const urlButtons = buttons.filter(
      (button) => button.type === WhatsappButtonType.URL
    );
    const quickReplyButtons = buttons.filter(
      (button) => button.type === "QUICK_REPLY"
    );

    if (quickReplyButtons.length > 3) {
      errors.push("You can have a maximum of 3 Quick Reply buttons");
    }

    const phoneButtons = buttons.filter(
      (button) => button.type === WhatsappButtonType.PHONE_NUMBER
    );

    if (phoneButtons.length > 1) {
      errors.push("You can only have one Phone button");
    }

    if (urlButtons.length > 1) {
      errors.push("You can only have one URL button");
    }

    buttons.forEach((button, index) => {
      if (!button.text) {
        errors.push(`Button ${index + 1} text is required`);
      }

      if (button.text && button.text.length > 25) {
        errors.push(`Button ${index + 1} text must be 25 characters or less`);
      }

      if (button.type === WhatsappButtonType.URL) {
        if (!button.url) {
          errors.push("URL is required for URL button");
        } else if (!isValidUrl(button.url)) {
          errors.push(`Invalid URL for button: ${button.url}`);
        }
      }

      if (button.type === WhatsappButtonType.PHONE_NUMBER) {
        if (!button.phoneNumber) {
          errors.push("Phone number is required for Phone button");
        } else if (!isValidPhoneNumber(button.phoneNumber)) {
          errors.push(`Invalid phone number for button: ${button.phoneNumber}`);
        }
      }
    });
  }

  return errors;
};

export const defaultCustomFieldsDefinition: {
  [key: string]: string;
} = {
  contact_first_name: "Contact First Name",
  contact_last_name: "Contact Last Name",
  agent_first_name: "Agent First Name",
  agent_last_name: "Agent Surname",
  agent_name: "Agent Name",
  agent_email: "Agent Email",
  agent_phone: "Agent Phone",
  merchant_name: "Merchant Name",
  review_link: "Review Link",
};
