import { useAuth0 } from "@auth0/auth0-react";
import {
  Avatar,
  Badge,
  Button,
  HStack,
  Icon,
  Text,
  useBreakpointValue,
  useColorMode,
  useToast,
} from "@chakra-ui/react";
import { ReactComponent as Checkmark } from "assets/icons/Correct-ds.svg";
import { AgentMerchantDomain } from "entities/domain/agents/new-agent-domain";
import useShortcut from "hooks/use-key-press";
import React, { useCallback, useMemo } from "react";
import { batch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { resetStore as resetAgentsStore } from "redux/features/agents";
import { resetStore as resetAudiencesStore } from "redux/features/audiences";
import { resetStore as resetCalendarStore } from "redux/features/calendar";
import { resetStore as resetCampaignsStore } from "redux/features/campaigns";
import { resetStore as resetContactsStore } from "redux/features/contacts";
import { resetStore as resetConversationsStore } from "redux/features/conversations";
import { resetStore as resetFilesStore } from "redux/features/files";
import { resetStore as resetInventoryStore } from "redux/features/inventory";
import {
  setIsSelectorOpened,
  updateMerchant,
  updateMerchantSuccess,
} from "redux/features/merchant";
import { resetStore as resetMessagesStore } from "redux/features/messages";
import { resetStore as resetNotificationsStore } from "redux/features/notifications";
import { resetStore as resetOnboardingStore } from "redux/features/onboarding";
import { resetStore as resetPaymentsStore } from "redux/features/payments";
import { resetStore as resetPublicPaymentsStore } from "redux/features/publicPayments";
import { resetStore as resetTagsStore } from "redux/features/tags";
import { resetStore as resetTemplatesStore } from "redux/features/templates";
import { resetStore as resetXeroStore } from "redux/features/xero";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import MerchantService from "services/merchant";

interface DesktopSwitchButtonProps {
  merchantOption: AgentMerchantDomain;
  unreadCount?: number;
  index: number;
  size?: "sm" | "md" | "lg";
  overwrightColorMode?: "light" | "dark";
  buttonColorScheme?: string;
}

const DesktopSwitchButton = ({
  merchantOption,
  unreadCount = 0,
  index,
  size = "sm",
  buttonColorScheme,
  overwrightColorMode,
}: DesktopSwitchButtonProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const auth0Context = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { merchant } = useAppSelector((state) => state.merchant);
  const { currentAgent } = useAppSelector((state) => state.agents);

  const loadMerchant = useCallback(
    async (id: number): Promise<void> => {
      try {
        dispatch(updateMerchant());

        const merchantResponse = await MerchantService.getMerchant(
          auth0Context,
          id
        );

        if (!merchantResponse) {
          toast({
            status: "error",
            title:
              "Error switching merchant, please refresh the page and try again!",
          });

          return;
        }

        batch(() => {
          dispatch(resetTemplatesStore());
          dispatch(resetAgentsStore());
          dispatch(resetConversationsStore());
          dispatch(resetAudiencesStore());
          dispatch(resetCalendarStore());
          dispatch(resetCampaignsStore());
          dispatch(resetContactsStore());
          dispatch(resetFilesStore());
          dispatch(resetInventoryStore());
          dispatch(resetMessagesStore());
          dispatch(resetNotificationsStore());
          dispatch(resetOnboardingStore());
          dispatch(resetPaymentsStore());
          dispatch(resetPublicPaymentsStore());
          dispatch(resetTagsStore());
          dispatch(resetXeroStore());
          dispatch(updateMerchantSuccess(merchantResponse));
          dispatch(setIsSelectorOpened(false));
        });

        localStorage.setItem("fuzey:merchant:id", id.toString());
        navigate(
          `/${id}/${location.pathname.split("/").filter((p) => !!p)[1]}`
        );
      } catch (err) {
        // eslint-disable-next-line
        console.error("Error fetching merchant", err);

        toast({
          status: "error",
          title: "Error switching merchant, please try again later!",
        });
      }
    },
    [location.pathname]
  );

  const onKeyPress = useCallback(() => {
    if (merchantOption.id === merchant.id) {
      return;
    }

    loadMerchant(merchantOption.id);
  }, [merchantOption.id, merchant.id]);

  const shortcut = useShortcut(
    index,
    merchantOption.name,
    onKeyPress,
    currentAgent?.merchants?.length || 1
  );

  const leftIcon = useMemo(() => {
    return (
      <HStack justifyContent="start" alignItems="center" spacing={1}>
        <Avatar
          size="xs"
          name={merchantOption.name}
          src={merchantOption.logo || undefined}
        />
      </HStack>
    );
  }, [merchantOption.name, merchantOption.logo]);

  const rightIcon = useMemo(() => {
    if (merchantOption.id === merchant.id) {
      return (
        <Icon
          as={Checkmark}
          __css={{
            rect: {
              fill: "transparent",
            },
            path: {
              fill: colorMode === "dark" ? "green.100" : "green.500",
            },
          }}
        />
      );
    }

    if (unreadCount) {
      return <Badge colorScheme="red">{unreadCount}</Badge>;
    } else if (!isBaseSize) {
      return <Text>{shortcut}</Text>;
    }
  }, [
    merchantOption.id,
    merchant.id,
    unreadCount,
    colorMode,
    shortcut,
    isBaseSize,
  ]);

  return (
    <Button
      flexShrink={0}
      isActive={merchantOption.id === merchant.id}
      pointerEvents={merchantOption.id === merchant.id ? "none" : "auto"}
      colorScheme={buttonColorScheme || colorScheme}
      _hover={{
        textDecorationLine: "none",
        bgColor: "whiteAlpha.100",
      }}
      variant={!buttonColorScheme ? "ghost" : "solid"}
      bgColor={
        !buttonColorScheme
          ? (overwrightColorMode || colorMode) === "dark"
            ? `${colorScheme}.800`
            : `${colorScheme}.50`
          : (overwrightColorMode || colorMode) === "dark"
          ? `${buttonColorScheme}.800`
          : `${buttonColorScheme}.50`
      }
      color={
        !buttonColorScheme
          ? (overwrightColorMode || colorMode) === "dark"
            ? "gray.50"
            : "gray.900"
          : (overwrightColorMode || colorMode) === "dark"
          ? `${buttonColorScheme}.50`
          : `${buttonColorScheme}.800`
      }
      onClick={() => {
        loadMerchant(merchantOption.id);
      }}
      key={merchantOption.id}
      w="100%"
      display="flex"
      size={size}
      justifyContent="space-between"
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      pl={2}
    >
      <Text
        flexGrow={1}
        textAlign="left"
        textOverflow="ellipsis"
        overflow="hidden"
        whiteSpace="nowrap"
        color="inherit"
      >
        {currentAgent?.getGroupName()
          ? merchantOption.getNameWithoutGroup()
          : merchantOption.name}
      </Text>
    </Button>
  );
};

export default DesktopSwitchButton;
