import { Flex, useBreakpointValue } from "@chakra-ui/react";
import React, { memo, useCallback, useMemo } from "react";
import { MessageInputTab } from "redux/features/conversations";
import { useAppSelector } from "redux/hooks";
import EmojiShortcut from "./input-actions/EmojiShortcut";
import MoreActions from "./input-actions/MoreActions";
import TemplatesShortcut from "./input-actions/TemplatesShortcut";

interface InputActionsProps {
  setIsLoadingSuggestion: (loading: boolean) => void;
  insertText: (text: string) => void;
}

const InputActions = ({
  insertText,
  setIsLoadingSuggestion,
}: InputActionsProps) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { merchant } = useAppSelector((state) => state.merchant);
  const {
    activeConversation,
    messageInput: { activeTab },
  } = useAppSelector((state) => state.conversations);

  const onEmojiClick = useCallback(
    ({ native }: { native: string }) => {
      insertText(native);
    },
    [insertText]
  );

  const isDisabled = useMemo(() => {
    return !!activeConversation?.isChannelDisconnected(merchant);
  }, [activeConversation, merchant]);

  return (
    <Flex
      alignSelf="center"
      justifyContent="start"
      gridGap={1}
      zIndex={101}
      // mr="auto" not needed?
    >
      <MoreActions
        setIsLoadingSuggestion={setIsLoadingSuggestion}
        isDisabled={isDisabled}
      />
      <TemplatesShortcut isDisabled={isDisabled} />
      {isBaseSize ||
      activeConversation?.isTemplatesOnly() ||
      isDisabled ||
      activeTab === MessageInputTab.PREVIEW ? null : (
        <EmojiShortcut onEmojiClick={onEmojiClick} />
      )}
    </Flex>
  );
};

export default memo(InputActions);
