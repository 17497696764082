import { HStack, useDisclosure } from "@chakra-ui/react";
import Gallery, { Media } from "components/shared/Gallery";
import React, { useCallback, useMemo, useState } from "react";
import {
  MessageInputAttachment,
  removeAttachmentFromMessageInput,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Attachment from "./attachment";

interface AttachmentsProps {
  size?: "sm" | "md";
}

const Attachments: React.FC<AttachmentsProps> = ({ size = "sm" }) => {
  const dispatch = useAppDispatch();
  const {
    messageInput: { attachments, template },
  } = useAppSelector((state) => state.conversations);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [startIndex, setStartIndex] = useState<number>(0);

  const openGallery = (index: number) => {
    setStartIndex(index);
    onOpen();
  };

  const isRemovable = useMemo(() => {
    return !template;
  }, [template]);

  const actualAttachments = useMemo<MessageInputAttachment[]>(() => {
    if (template) {
      const templateMedia = template.getMedia();

      if (templateMedia) {
        return [
          {
            id: template.id, // Almost random
            url: template.mediaUrl!,
            type: template.mediaType!,
          },
        ];
      }
    }

    return attachments;
  }, [template, attachments]);

  return !actualAttachments.length ? null : (
    <HStack w="100%" my={2} pl={2}>
      {actualAttachments.map((attachment, index) => (
        <Attachment
          size={size}
          attachment={attachment}
          onOpenGallery={() => openGallery(index)}
          onAttachmentRemove={
            isRemovable
              ? () => {
                  dispatch(removeAttachmentFromMessageInput(index));
                }
              : undefined
          }
        />
      ))}
      <Gallery
        isOpen={isOpen}
        onClose={onClose}
        startIndex={startIndex}
        setStartIndex={setStartIndex}
        media={actualAttachments}
      />
    </HStack>
  );
};

export default Attachments;
