import { useAuth0 } from "@auth0/auth0-react";
import {
  Badge,
  Button,
  HStack,
  Text,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import Topbar from "components/shared/topbar/TopBar";
import { UnreadCountDTO } from "entities/dto/UnreadCountDTO";
import React, { memo, useCallback, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Select, { OptionProps, SingleValue, components } from "react-select";
import { setTeams } from "redux/features/agents";
import {
  clearSelectedConversations,
  setSelectedInbox,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AgentsService from "services/agents";
import { getReactSelectStyles } from "util/methods";
import NewConversationModal from "../shared/start-conversation-area";
import StartConversationButton from "../shared/top-area/StartConversationButton";

interface TeamSelectOption {
  value: string;
  label: string;
}

const getUnreadTotalForTeam = (
  teamId: string,
  unreadCount?: UnreadCountDTO
): number => {
  if (!unreadCount || !unreadCount.teams || !unreadCount.teams[teamId]) {
    return 0;
  }

  const unreadCountTotal =
    unreadCount.teams[teamId].personal +
    unreadCount.teams[teamId].shared +
    unreadCount.teams[teamId].unassigned;

  return unreadCountTotal;
};

const MobileTopBar = () => {
  const auth0Context = useAuth0();
  const { colorMode } = useColorMode();
  const { teams, agents, unreadCounts } = useAppSelector(
    (state) => state.agents
  );
  const { activeConversationId, selectedConversationIds, selectedInbox } =
    useAppSelector((state) => state.conversations);
  const { merchant } = useAppSelector((state) => state.merchant);
  const unreadCount = unreadCounts.find((uc) => uc.merchant_id === merchant.id);
  const [currentSearchParameters, setSearchParams] = useSearchParams();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const dispatch = useAppDispatch();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  // Pass unreadCount as a prop to component later on
  const TeamOption = function (props: OptionProps<TeamSelectOption>) {
    return (
      <components.Option {...props}>
        <HStack justifyContent="space-between">
          <Text>{props.data.label}</Text>
          {getUnreadTotalForTeam(props.data.value, unreadCount) > 0 ? (
            <Badge colorScheme="red">
              {getUnreadTotalForTeam(props.data.value, unreadCount) > 100
                ? "100+"
                : getUnreadTotalForTeam(props.data.value, unreadCount)}
            </Badge>
          ) : null}
        </HStack>
      </components.Option>
    );
  };

  const [isNewConversationModalOpen, setIsNewConversationModalOpen] =
    useState<boolean>(false);

  const onStartConversationButtonClick = useCallback(
    () => setIsNewConversationModalOpen(true),
    []
  );
  const onCloseNewConversationModal = useCallback(
    () => setIsNewConversationModalOpen(false),
    []
  );

  const [teamOptions, setTeamOptions] = useState<TeamSelectOption[]>(
    teams.map((team) => ({
      label: team.name,
      value: team.id,
    }))
  );
  const [selectedTeamValue, setSelectedTeamValue] = useState<
    SingleValue<TeamSelectOption>
  >(
    selectedInbox
      ? teamOptions.find((to) => to.value === selectedInbox) || null
      : null
  );

  useEffect(() => {
    AgentsService.getTeams(auth0Context, merchant.id)
      .then((allTeams) => {
        dispatch(setTeams(allTeams));
      })
      .catch(() => {});
  }, [merchant.id]);

  useEffect(() => {
    const queryParams = new URLSearchParams(currentSearchParameters);
    const teamInbox = queryParams.get("team") as string | null;

    if (teamInbox !== selectedTeamValue?.value) {
      dispatch(setSelectedInbox(teamInbox));
    }
  }, [currentSearchParameters]);

  useEffect(() => {
    const queryParams = new URLSearchParams(currentSearchParameters);
    const teamInbox = queryParams.get("team") as string | null;

    if (selectedInbox && selectedInbox !== teamInbox) {
      setSearchParams({
        ...Object.fromEntries(currentSearchParameters.entries()),
        team: selectedInbox,
      });

      return;
    }
  }, [selectedInbox]);

  useEffect(() => {
    const newTeamOptions = teams.map((team) => ({
      label: team.name,
      value: team.id,
    }));

    if (selectedTeamValue) {
      const selectedTeamStillExists = newTeamOptions.find(
        (team) => team.value === selectedTeamValue.value
      );

      if (!selectedTeamStillExists) {
        setSelectedTeamValue(null);
      }
    } else if (selectedInbox) {
      setSelectedTeamValue(
        newTeamOptions.find((team) => team.value === selectedInbox) || null
      );
    }

    setTeamOptions(newTeamOptions);
  }, [teams]);

  if (activeConversationId) {
    return null;
  }

  if (!isBaseSize) {
    return null;
  }

  return (
    <>
      <Topbar
        titleSize={selectedConversationIds.length === 0 ? "2xl" : "lg"}
        title={
          agents.length <= 1 ? (
            "Chat"
          ) : (
            <Select
              isMulti={false}
              placeholder="All teams"
              onChange={(newTeam) => {
                setSelectedTeamValue(newTeam);

                if (!newTeam) {
                  currentSearchParameters.delete("team");

                  setSearchParams({
                    ...Object.fromEntries(currentSearchParameters.entries()),
                  });

                  return;
                }

                dispatch(setSelectedInbox(newTeam.value));
              }}
              isClearable={true}
              options={teamOptions}
              value={selectedTeamValue}
              components={{
                Option: TeamOption,
              }}
              styles={{
                ...{
                  ...getReactSelectStyles(colorMode, colorScheme),
                  container: (provided: any) => ({
                    ...provided,
                    width: isBaseSize ? "100%" : "auto",
                  }),
                },
              }}
            />
          )
        }
        rightChildrenMobile={
          selectedConversationIds.length === 0 ? (
            <StartConversationButton onClick={onStartConversationButtonClick} />
          ) : (
            <Button
              colorScheme={colorScheme}
              onClick={() => {
                dispatch(clearSelectedConversations());
              }}
            >
              Clear
            </Button>
          )
        }
      />
      <NewConversationModal
        isOpen={isNewConversationModalOpen}
        onClose={onCloseNewConversationModal}
      />
    </>
  );
};

export default memo(MobileTopBar);
