import { HStack, Text, useColorMode } from "@chakra-ui/react";
import ConversationDomain, {
  ConversationChannel,
} from "entities/domain/conversations/conversation-domain";
import MessageDomain, {
  MessageDirection,
} from "entities/domain/conversations/message-domain";
import TemplateDomain from "entities/domain/templates";
import React, { useEffect, useState } from "react";
import { messagesSelector } from "redux/features/messages";
import { useAppSelector } from "redux/hooks";
import TemplatesShortcut from "../../input-actions/TemplatesShortcut";
import InfoModalWhatsapp from "./InfoModalWhatsapp";

interface FirstOutreachMessageProps {
  template: TemplateDomain | null;
  isShown: boolean;
}

const shouldShowOverlayForWhatsappConversation = (
  conversation: ConversationDomain | null | undefined,
  template: TemplateDomain | null,
  lastMessage?: MessageDomain
) => {
  if (!conversation) {
    return false;
  }

  return (
    conversation.channel === ConversationChannel.WHATSAPP &&
    conversation.isTemplatesOnly() &&
    !template &&
    !(lastMessage && lastMessage.isUnreachable())
  );
};

const FirstOutreachMessage = ({
  template,
  isShown: isForcefullyShown,
}: FirstOutreachMessageProps) => {
  const { activeConversation } = useAppSelector((state) => state.conversations);
  const conversationMessages = useAppSelector(messagesSelector);
  const lastOutgoingMessage = [...conversationMessages]
    .reverse()
    .find((m) => m.direction === MessageDirection.OUTGOING);
  const { colorMode } = useColorMode();
  const [isShown, setIsShown] = useState<boolean>(
    isForcefullyShown ||
      shouldShowOverlayForWhatsappConversation(
        activeConversation,
        template,
        lastOutgoingMessage
      )
  );

  useEffect(() => {
    setIsShown(
      isForcefullyShown ||
        shouldShowOverlayForWhatsappConversation(
          activeConversation,
          template,
          lastOutgoingMessage
        )
    );
  }, [activeConversation, lastOutgoingMessage, template, isForcefullyShown]);

  return (
    <HStack
      backgroundColor={colorMode === "dark" ? "gray.700" : "white"}
      cursor="pointer"
      justifyContent="start"
      px={4}
      pt={4}
      pb={8}
      alignItems="start"
    >
      <HStack alignItems="center">
        <Text>Click here to choose an approved WhatsApp template</Text>
        <InfoModalWhatsapp />
      </HStack>
      <TemplatesShortcut invisibleTrigger={true} />
    </HStack>
  );
};

export default FirstOutreachMessage;
