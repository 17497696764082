import { HStack, Spacer, Textarea } from "@chakra-ui/react";
import AddCustomField from "components/shared/AddCustomField";
import ChannelDomain from "entities/domain/contacts/contact-domain";
import TemplateDomain from "entities/domain/templates";
import useAvailableCustomFields from "hooks/use-available-custom-fields";
import useDebounce from "hooks/use-debounce";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import TextareaAutosize from "react-textarea-autosize";
import {
  MessageInputTab,
  changeMessageInputText,
  setMessageInputTemplate,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import Attachments from "../../Attachments";
import ClearInputButton from "../../ClearInputButton";
import Content from "../../Content";
import EmptyCustomFieldsWarning from "../../EmptyCustomFieldsWarning";
import Footer from "../../Footer";
import InputActions from "../../InputActions";
import MaxLengthWarning from "../../MaxLengthWarning";
import SendButton from "../../SendButton";
import TypingZoneOverlay from "../../TypingZoneOverlay";
import Wrapper from "../../Wrapper";

interface InstagramInputProps {}

const INSTAGRAM_LIMIT = 2000;

const InstagramInput: React.FC<InstagramInputProps> = () => {
  const dispatch = useAppDispatch();
  const { merchant } = useAppSelector((state) => state.merchant);
  const {
    activeConversation,
    activeConversationId,
    templates: conversationTemplates,
    messageInput: { text, attachments, template, activeTab },
  } = useAppSelector((state) => state.conversations);
  const [isLimitExceeded, setIsLimitExceeded] = useState<boolean>(false);
  const [isApproachingLimit, setIsApproachingLimit] = useState<boolean>(false);
  const [localText, setLocalText] = useState<string>(
    template ? template.text : text
  );
  const debouncedLocalText = useDebounce(localText, 500);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [isLoadingSuggestion, setIsLoadingSuggestion] =
    useState<boolean>(false);

  useEffect(() => {
    if (text === debouncedLocalText) {
      return;
    }

    dispatch(changeMessageInputText(debouncedLocalText));
  }, [debouncedLocalText]);

  const templatesShortcuts = (
    activeConversationId
      ? conversationTemplates[activeConversationId] || []
      : []
  ).reduce(
    (shortcuts: { [key: string]: TemplateDomain }, t: TemplateDomain) => {
      const newShortcuts = { ...shortcuts };

      if (t.shortcut) {
        newShortcuts[t.shortcut] = t;
      }

      return newShortcuts;
    },
    {}
  );

  const { availableCustomFields } = useAvailableCustomFields(template);

  const onTextChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement> | string) => {
      const value = typeof event === "string" ? event : event.target.value;

      if (value === localText) {
        return;
      }

      setLocalText(value);

      if (!value) {
        dispatch(setMessageInputTemplate(null));
      }

      const templateShortcut = /^\/(.*)$/;
      const shortcutAttempt = value.match(templateShortcut);
      const foundTemplate =
        shortcutAttempt && templatesShortcuts[shortcutAttempt[1]];

      if (foundTemplate) {
        dispatch(setMessageInputTemplate(foundTemplate));
        setLocalText(
          TemplateDomain.getTextFromTemplate(
            foundTemplate.text,
            foundTemplate.customFields
          )
        );

        return;
      }
    },
    [localText, templatesShortcuts]
  );

  useEffect(() => {
    if (!template) {
      return;
    }

    dispatch(changeMessageInputText(template.text));
  }, [template?.id]);

  useEffect(() => {
    if (text === localText) {
      return;
    }

    setLocalText(text);
  }, [text]);

  const isDisabled = useMemo(() => {
    if (activeConversation?.isChannelDisconnected(merchant)) {
      return true;
    }

    if (isLimitExceeded) {
      return true;
    }

    const isFileAttached = !!attachments.length;
    const messageIsEmpty = (localText.trim().length || 0) === 0;
    const messageExceedsLimits = INSTAGRAM_LIMIT
      ? (localText.length || 0) > INSTAGRAM_LIMIT
      : false;
    const templateHasCustomFieldsWithoutValue = template
      ? TemplateDomain.containsCustomFieldsWithoutValue(
          template.text,
          template.customFields
        )
      : false;

    if (templateHasCustomFieldsWithoutValue) {
      return true;
    }

    return !(
      isFileAttached ||
      !messageIsEmpty ||
      (!messageIsEmpty && !messageExceedsLimits)
    );
  }, [
    attachments,
    localText,
    template,
    activeConversation,
    merchant,
    isLimitExceeded,
  ]);

  const insertText = useCallback(
    (newPieceOfText: string) => {
      if (!textAreaRef.current) {
        return;
      }

      const { selectionStart, selectionEnd } = textAreaRef.current;

      const newText = [
        localText.slice(0, selectionStart),
        newPieceOfText,
        localText.slice(selectionEnd),
      ].join("");

      setLocalText(newText);
      textAreaRef.current.focus();
    },
    [textAreaRef.current, localText]
  );

  const messagePlaceholder = useMemo(() => {
    if (!activeConversation) {
      return "";
    }

    if (activeConversation.isChannelDisconnected(merchant)) {
      return `Please reconnect your ${ChannelDomain.getChannelName(
        activeConversation.channel
      )} account to send message`;
    }

    return "Type here to send a message";
  }, [activeConversation, merchant.channels]);

  return (
    <Wrapper>
      <TypingZoneOverlay isShown={isLoadingSuggestion} borderRadius="xl" />
      <Content
        topRightElement={
          <HStack w="100%" px={2}>
            <Spacer />
            {!template ? null : (
              <ClearInputButton
                onClick={() => setLocalText("")}
                isDisabled={isDisabled}
              />
            )}
          </HStack>
        }
      >
        <Textarea
          as={TextareaAutosize}
          autoFocus={true}
          minHeight="5rem"
          maxHeight="auto"
          ref={textAreaRef}
          placeholder={messagePlaceholder}
          p={2}
          lineHeight="inherit"
          value={localText}
          onChange={onTextChange}
          outline="none"
          border="none"
          borderRadius={0}
          boxShadow="none"
          resize="none"
        />
        <Attachments />
      </Content>
      <Footer>
        <InputActions
          setIsLoadingSuggestion={setIsLoadingSuggestion}
          insertText={insertText}
        />
        {!template || activeTab === MessageInputTab.PREVIEW ? null : (
          <AddCustomField
            isDisabled={false}
            useSmallVersion={true}
            customFields={availableCustomFields}
            size="xs"
            onCustomFieldSelect={(cf) => insertText(cf)}
            addNewCustomField={(key, value) => {
              dispatch(
                setMessageInputTemplate(
                  Object.setPrototypeOf(
                    {
                      ...template,
                      customFields: {
                        ...template.customFields,
                        [key]: value,
                      },
                    },
                    TemplateDomain.prototype
                  )
                )
              );
              insertText(key);
            }}
          />
        )}
        <MaxLengthWarning
          max={INSTAGRAM_LIMIT}
          currentLength={localText.length}
          setIsLimitExceeded={setIsLimitExceeded}
          setIsApproachingLimit={setIsApproachingLimit}
        />
        {isLimitExceeded || isApproachingLimit ? null : (
          <EmptyCustomFieldsWarning text={localText} />
        )}
        <Spacer />
        <SendButton
          text={localText}
          isDisabled={isDisabled}
          onMessageSent={() => {
            setLocalText("");
          }}
          textAreaRef={textAreaRef}
        />
      </Footer>
    </Wrapper>
  );
};

export default InstagramInput;
