import { ArrowForwardIcon, ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Tooltip,
  VStack,
  useBreakpointValue,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { ReactComponent as BracesIcon } from "assets/icons/braces-svgrepo-com.svg";
import React, { useCallback, useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useAppSelector } from "redux/hooks";

interface AddCustomFieldProps {
  useSmallVersion?: boolean;
  isDisabled: boolean;
  customFields: { [key: string]: string };
  onCustomFieldSelect: (cf: string) => void;
  size?: "xs" | "sm" | "md" | "lg";
  addNewCustomField: (key: string, value: string) => void;
}

const humanizeCustomFieldKey = (key: string) => {
  return key
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const AddCustomField: React.FC<AddCustomFieldProps> = ({
  isDisabled,
  customFields,
  size = "sm",
  useSmallVersion = false,
  onCustomFieldSelect,
  addNewCustomField,
}) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const createCustomFieldModal = useDisclosure();

  const [newCustomFieldKey, setNewCustomFieldKey] = useState<string>("");
  const [newCustomFieldValue, setNewCustomFieldValue] = useState<string>("");
  const [newCustomFieldKeyError, setNewCustomFieldKeyError] =
    useState<string>("");

  const resetCustomFieldForm = useCallback(() => {
    setNewCustomFieldKey("");
    setNewCustomFieldValue("");
    setNewCustomFieldKeyError("");
  }, []);

  useEffect(() => {
    if (!newCustomFieldKey) {
      setNewCustomFieldKeyError("");
      return;
    }

    if (!/^[a-z_]+$/.test(newCustomFieldKey)) {
      setNewCustomFieldKeyError(
        "Name should contain only lowercase letters and underscores"
      );
    } else {
      setNewCustomFieldKeyError("");
    }
  }, [newCustomFieldKey]);

  return (
    <>
      <Menu>
        {isBaseSize || useSmallVersion ? (
          <Tooltip isDisabled={isBaseSize} label="Add custom field">
            <MenuButton
              isDisabled={isDisabled}
              colorScheme="green"
              size={size}
              zIndex={1}
              as={IconButton}
              icon={
                <Icon
                  as={BracesIcon}
                  __css={{
                    "path:last-child": {
                      fill: colorMode === "dark" ? "black" : "white",
                    },
                  }}
                />
              }
            />
          </Tooltip>
        ) : (
          <MenuButton
            isDisabled={isDisabled}
            size={size}
            className="menu-custom-field-button"
            colorScheme="green"
            as={Button}
            rightIcon={<ChevronDownIcon />}
            leftIcon={
              <Icon
                as={BracesIcon}
                __css={{
                  "path:last-child": {
                    fill: colorMode === "dark" ? "black" : "white",
                  },
                }}
              />
            }
          >
            Add Custom Field
          </MenuButton>
        )}
        <Portal>
          <MenuList zIndex={99}>
            {Object.entries(customFields).map(([key, _value]) => (
              <MenuItem
                key={key}
                onClick={() => {
                  if (isDisabled) {
                    return;
                  }
                  onCustomFieldSelect(`{${key}}`);
                }}
              >
                {humanizeCustomFieldKey(key)}
              </MenuItem>
            ))}
            <MenuDivider />
            <MenuItem
              onClick={createCustomFieldModal.onOpen}
              icon={<Icon as={FaPlus} />}
            >
              Create
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
      <Modal
        isOpen={createCustomFieldModal.isOpen}
        onClose={createCustomFieldModal.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Personalized Custom Field</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <FormControl isInvalid={!!newCustomFieldKeyError} isRequired>
                <FormLabel>Name</FormLabel>
                <Input
                  placeholder="pet_name"
                  value={newCustomFieldKey}
                  onChange={(e) => setNewCustomFieldKey(e.target.value)}
                />
                <FormErrorMessage>{newCustomFieldKeyError}</FormErrorMessage>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Example</FormLabel>
                <Input
                  placeholder="Doctor Dog"
                  value={newCustomFieldValue}
                  onChange={(e) => setNewCustomFieldValue(e.target.value)}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter display="flex" justifyContent="space-between">
            <Button
              colorScheme={colorScheme}
              variant="ghost"
              onClick={createCustomFieldModal.onClose}
            >
              Close
            </Button>
            <Button
              colorScheme={colorScheme}
              rightIcon={<ArrowForwardIcon />}
              onClick={() => {
                addNewCustomField(newCustomFieldKey, newCustomFieldValue);
                onCustomFieldSelect(`{${newCustomFieldKey}}`);
                resetCustomFieldForm();
                createCustomFieldModal.onClose();
              }}
              isDisabled={
                !newCustomFieldKey ||
                !newCustomFieldValue ||
                !!newCustomFieldKeyError
              }
            >
              Create & Use
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default AddCustomField;
