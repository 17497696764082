import {
  ButtonGroup,
  HStack,
  IconButton,
  Tooltip,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useCallback } from "react";

interface WhatsappMarkdownToolbarProps {
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  setText: React.Dispatch<React.SetStateAction<string>>;
  size?: "xs" | "sm" | "md" | "lg";
}

const WhatsappMarkdownToolbar: React.FC<WhatsappMarkdownToolbarProps> = ({
  textAreaRef,
  setText,
  size = "sm",
}) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const wrapTextInBold = useCallback(() => {
    wrapText("*", "*");
  }, [textAreaRef]);

  const wrapTextInItalic = useCallback(() => {
    wrapText("_", "_");
  }, [textAreaRef]);

  const wrapTextInStrikethrough = useCallback(() => {
    wrapText("~", "~");
  }, [textAreaRef]);

  const wrapText = useCallback(
    (leftText: string, rightText: string) => {
      if (!textAreaRef || !textAreaRef.current) {
        return;
      }

      const cursorPosition = textAreaRef.current.selectionStart;
      const selectionEnd = textAreaRef.current.selectionEnd;

      setText((oldText) => {
        const trimmedOldText = oldText.trim();
        const newText =
          trimmedOldText.substring(0, cursorPosition) +
          leftText +
          trimmedOldText.substring(cursorPosition, selectionEnd) +
          rightText +
          trimmedOldText.substring(selectionEnd);

        return newText;
      });

      textAreaRef.current.focus();
    },
    [textAreaRef]
  );

  return (
    <HStack>
      <ButtonGroup variant="outline" spacing={2} size={size}>
        <Tooltip isDisabled={isBaseSize} label="Bold">
          <IconButton
            aria-label="Bold"
            id="bold-button"
            isRound={true}
            colorScheme="gray"
            onClick={wrapTextInBold}
          >
            <b>B</b>
          </IconButton>
        </Tooltip>
        <Tooltip isDisabled={isBaseSize} label="Italic">
          <IconButton
            aria-label="Italic"
            id="italic-button"
            isRound={true}
            colorScheme="gray"
            fontStyle="italic"
            onClick={wrapTextInItalic}
          >
            <i>I</i>
          </IconButton>
        </Tooltip>
        <Tooltip isDisabled={isBaseSize} label="Strikethrough">
          <IconButton
            aria-label="Strikethrough"
            id="strikethrough-button"
            isRound={true}
            colorScheme="gray"
            onClick={wrapTextInStrikethrough}
          >
            <s>S</s>
          </IconButton>
        </Tooltip>
      </ButtonGroup>
    </HStack>
  );
};

export default WhatsappMarkdownToolbar;
