import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import React, { useEffect, useMemo, useRef } from "react";
import {
  MessageInputTab,
  changeMessageInputText,
  getMessageInputStateFromLocalStorage,
  isMessageInputStateEmpty,
  setMessageInputState,
  setMessageInputTemplate,
  updateMessageInputActiveTab,
} from "redux/features/conversations";
import { setAutoReplySuggestion } from "redux/features/messages";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import EmailInput from "./channels/email/EmailInput";
import InstagramInput from "./channels/instagram/InstagramInput";
import MessengerInput from "./channels/messenger/MessengerInput";
import SmsInput from "./channels/sms/SmsInput";
import WhatsappInput from "./channels/whatsapp/WhatsappInput";

interface TypingZoneProps {}

const TypingZone: React.FC<TypingZoneProps> = () => {
  const dispatch = useAppDispatch();
  const { activeConversation, activeConversationId, messageInput } =
    useAppSelector((state) => state.conversations);
  const messageStateLoaded = useRef<boolean>(false);
  const { autoReplySuggestion } = useAppSelector((state) => state.messages);

  // Needs polishing
  // const draftMessageInputStateId = activeConversationId
  //   ? `fuzey-draft-state-${activeConversationId}`
  //   : null;

  // useEffect(() => {
  //   if (!draftMessageInputStateId) {
  //     return;
  //   }

  //   const messageInputState = getMessageInputStateFromLocalStorage(
  //     draftMessageInputStateId
  //   );

  //   messageStateLoaded.current = true;

  //   if (!messageInputState || isMessageInputStateEmpty(messageInputState)) {
  //     return;
  //   }

  //   dispatch(setMessageInputState(messageInputState));
  // }, [draftMessageInputStateId]);

  // useEffect(() => {
  //   if (
  //     !activeConversationId ||
  //     !messageStateLoaded.current ||
  //     !draftMessageInputStateId
  //     // somehow check if the message input state was restored for the first time
  //     // fixme
  //   ) {
  //     return;
  //   }

  //   if (isMessageInputStateEmpty(messageInput)) {
  //     localStorage.removeItem(draftMessageInputStateId);
  //     return;
  //   }

  //   const draftState = JSON.stringify(messageInput);

  //   localStorage.setItem(draftMessageInputStateId, draftState);
  // }, [
  //   messageInput.text,
  //   messageInput.template,
  //   messageInput.attachments,
  //   messageInput.subject,
  //   messageInput.activeTab,
  // ]);

  useEffect(() => {
    if (autoReplySuggestion) {
      dispatch(changeMessageInputText(autoReplySuggestion));
      dispatch(updateMessageInputActiveTab(MessageInputTab.EDIT));
      dispatch(setMessageInputTemplate(null));
      dispatch(setAutoReplySuggestion(undefined));
    }
  }, [autoReplySuggestion]);

  const typingZoneByChannel = useMemo(() => {
    if (!activeConversation) {
      return null;
    }

    switch (activeConversation?.channel) {
      case ConversationChannel.SMS:
        return <SmsInput />;
      case ConversationChannel.WHATSAPP:
        return <WhatsappInput />;
      case ConversationChannel.FACEBOOK:
        return <MessengerInput />;
      case ConversationChannel.INSTAGRAM:
        return <InstagramInput />;
      case ConversationChannel.EMAIL:
        return <EmailInput />;
      case ConversationChannel.ZAPIER_FACEBOOK:
        return <MessengerInput />;
      case ConversationChannel.NO_CHANNEL:
      default:
        return null;
    }
  }, [activeConversation?.channel]);

  return typingZoneByChannel;
};

export default TypingZone;
