// https://webperf.tips/tip/react-hook-paint/

import { useEffect } from "react";

function runAfterFramePaint(callback: () => void) {
  let rafId: number;
  let timeoutId: number;

  const executeCallback = () => {
    if (document.visibilityState === "hidden") {
      timeoutId = setTimeout(callback, 0) as unknown as number;
    } else {
      rafId = requestAnimationFrame(() => {
        const messageChannel = new MessageChannel();

        messageChannel.port1.onmessage = callback;
        messageChannel.port2.postMessage(undefined);
      });
    }
  };

  executeCallback();

  return () => {
    if (rafId) cancelAnimationFrame(rafId);
    if (timeoutId) clearTimeout(timeoutId);
  };
}

export default function useMarkFramePaint(
  callback: undefined | (() => void),
  enabled: boolean
) {
  useEffect(() => {
    if (!enabled || !callback) {
      return;
    }

    const cancel = runAfterFramePaint(callback);

    return () => {
      cancel();
    };
  }, [callback, enabled]);
}
