import { format, isThisWeek, isToday, isYesterday, parseISO } from "date-fns";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import { updateFormatAccordingToCountry } from "util/methods";
import { IntegrationName } from "../admin/merchants/merchant-integrations";
import { MessageStatus } from "./message-domain";

export enum ConversationChannel {
  SMS = "sms",
  WHATSAPP = "whatsapp",
  FACEBOOK = "facebook",
  INSTAGRAM = "instagram",
  EMAIL = "email",
  ZAPIER_FACEBOOK = "zapier_facebook",
  NO_CHANNEL = "no_channel",
}

export enum AllowedMessageType {
  ALL = "all",
  TEMPLATES_ONLY = "templates_only",
}

export default class ConversationDomain {
  id: number;

  merchantId: number;

  customerId: number;

  picture: string;

  displayName: string;

  customerFirstName: string;

  customerLastName: string;

  customerChannelId: string;

  alias: string;

  channel: ConversationChannel;

  isOpen: boolean;

  createdAt: string;

  unreadCount: number;

  assignedAgentId: number | null;

  assignedTeamId: string | null;

  isSubscribed: boolean;

  previewText: string;

  lastMessageStatus: MessageStatus;

  displayDate: Date;

  messageId: number;

  allowedMessagesType: AllowedMessageType;

  lastMessageTitle?: string;

  lastMessageIsIncoming?: boolean;

  tagIds: string[];

  getDisplayDate(): string {
    if (isToday(this.displayDate)) {
      return format(this.displayDate, "hh:mm a");
    } else if (isYesterday(this.displayDate)) {
      return "Yesterday";
    } else if (isThisWeek(this.displayDate)) {
      return format(this.displayDate, "eeee"); // Day of the week
    } else {
      return format(
        this.displayDate,
        updateFormatAccordingToCountry("dd/MM/yyyy")
      );
    }
  }

  isLastMessageUndelivered(): boolean {
    return this.lastMessageStatus === MessageStatus.UNDELIVERED;
  }

  isAnyUnread(): boolean {
    return this.unreadCount !== 0 && this.unreadCount !== null;
  }

  isTemplatesOnly(): boolean {
    return this.allowedMessagesType === AllowedMessageType.TEMPLATES_ONLY;
  }

  isChannelDisconnected(merchant: MerchantDomainBase): boolean {
    return (
      (this.isChannelFacebookOrInstagram() &&
        !merchant.isIntegrationEnabled(
          this.channel as unknown as IntegrationName
        )) ||
      !merchant.isMerchantChannelEnabled(this.channel)
    );
  }

  getAcceptedFileFormat(): string {
    let fileFormat;

    switch (this.channel) {
      case ConversationChannel.INSTAGRAM:
        fileFormat = "image/*";
        break;
      case ConversationChannel.EMAIL:
        fileFormat = "*";
        break;
      default:
        fileFormat = "video/mp4, image/*, application/pdf, audio/*";
        break;
    }

    return fileFormat;
  }

  isEmailChannel(): boolean {
    return this.channel === ConversationChannel.EMAIL;
  }

  isChannelFacebookOrInstagram(): boolean {
    return (
      this.channel === ConversationChannel.FACEBOOK ||
      this.channel === ConversationChannel.INSTAGRAM
    );
  }

  isPhoneBasedChannel(): boolean {
    return (
      this.channel === ConversationChannel.SMS ||
      this.channel === ConversationChannel.WHATSAPP
    );
  }

  constructor(
    id: number,
    merchantId: number,
    customerId: number,
    displayName: string,
    picture: string,
    customerChannelId: string,
    alias: string,
    channel: ConversationChannel,
    isOpen: boolean,
    createdAt: string,
    unreadCount: number,
    assignedAgentId: number | null,
    assignedTeamId: string | null,
    isSubscribed: boolean,
    previewText: string,
    lastMessageStatus: MessageStatus,
    displayDate: Date,
    messageId: number,
    allowedMessageType: AllowedMessageType,
    tagIds: string[],
    customerFirstName: string,
    customerLastName: string,
    lastMessageTitle?: string,
    lastMessageIsIncoming?: boolean
  ) {
    this.id = id;
    this.merchantId = merchantId;
    this.customerId = customerId;
    this.displayName = displayName;
    this.picture = picture;
    this.customerChannelId = customerChannelId;
    this.alias = alias;
    this.channel = channel;
    this.isOpen = isOpen;
    this.createdAt = createdAt;
    this.unreadCount = unreadCount;
    this.assignedAgentId = assignedAgentId;
    this.assignedTeamId = assignedTeamId;
    this.isSubscribed = isSubscribed;
    this.previewText = previewText;
    this.customerFirstName = customerFirstName;
    this.customerLastName = customerLastName;
    this.lastMessageStatus = lastMessageStatus;
    this.displayDate = displayDate;
    this.messageId = messageId;
    this.allowedMessagesType = allowedMessageType;
    this.tagIds = tagIds;
    this.lastMessageTitle = lastMessageTitle;
    this.lastMessageIsIncoming = lastMessageIsIncoming;
  }
}
