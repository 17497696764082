import {
  Avatar,
  Box,
  Divider,
  Flex,
  Icon,
  Spinner,
  Text,
  Tooltip,
  VStack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import chroma from "chroma-js";
import ReportPerAgentDomain from "entities/domain/report-per-agent";
import React, { useEffect, useState } from "react";
import Select, { SingleValue } from "react-select";
import { useAppSelector } from "redux/hooks";
import {
  convertSecondsToHoursMinutesSeconds,
  getReactSelectStyles,
} from "util/methods";
import {
  Bar,
  BarProps,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryTheme,
  VictoryTooltip,
  createContainer,
} from "victory";

interface BarChartProps {
  isLoading: boolean;
  reports: ReportPerAgentDomain[];
}

interface MetricOption {
  value: string;
  label: string;
}

interface ChartData {
  agent_number: string;
  metric_value: number | null;
}

function getColorForNumber(index: string, total: number): string {
  const parsedIndex = parseInt(index, 10);

  // Means there's no chart data available
  if (!Number.isInteger(parsedIndex)) {
    return chroma("gray").hex();
  }

  if (parsedIndex < 0 || parsedIndex > total) {
    throw new Error(`Number must be between 0 and N but got ${parsedIndex}`);
  }

  const colorScale = chroma
    .scale("Spectral")
    .mode("lch")
    .colors(total + 1);

  const mutedColors = colorScale.map((color) => {
    return chroma(color).desaturate(1).darken(0.25).hex();
  });

  const shuffledColors: string[] = [];
  for (let i = 0; i <= total; i++) {
    const shuffleIndex = (i * 31) % (total + 1); // 31 is a prime number to help with shuffling
    shuffledColors.push(mutedColors[shuffleIndex]);
  }

  return shuffledColors[parsedIndex];
}

const CustomTickLabelComponent = ({
  x,
  y,
  text: agentNumber,
  transform,
}: {
  x?: number;
  y?: number;
  text?: string;
  transform?: string;
}) => {
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { agents } = useAppSelector((state) => state.agents);

  if (!agentNumber) {
    return null;
  }

  const agentIndex = parseInt(agentNumber, 10) - 1;

  if (!Number.isInteger(agentIndex)) {
    return null;
  }

  const agent = agents[agentIndex];
  const color = getColorForNumber(agentNumber, agents.length);
  const fontColor = chroma(color).luminance() > 0.5 ? "black" : "white";

  const avatarHeightWidth = isBaseSize ? 20 : 10;

  return (
    <foreignObject
      x={(x || 0) - avatarHeightWidth}
      y={(y || 0) - avatarHeightWidth / 2}
      width={`${avatarHeightWidth}px`}
      height={`${avatarHeightWidth}px`}
      style={{ transform }}
    >
      <Tooltip label={agent.getFullName()} aria-label="Agent Name">
        <Avatar
          width={`${avatarHeightWidth}px`}
          height={`${avatarHeightWidth}px`}
          bgColor={color}
          color={fontColor}
          sx={{
            ".chakra-avatar__initials": {
              fontSize: "4px",
            },
          }}
          src={agent!.getPicture() || ""}
          name={agent!.getFullName()}
        />
      </Tooltip>
    </foreignObject>
  );
};

const CustomBarComponent = (props: BarProps) => {
  const { datum, style } = props;
  const { agents } = useAppSelector((state) => state.agents);
  const color = getColorForNumber(datum.agent_number, agents.length);
  const fontColor = chroma(color).luminance() > 0.5 ? "black" : "white";

  return (
    <Bar
      {...props}
      style={{
        ...style,
        fill: color,
        color: fontColor,
      }}
    />
  );
};

const VictoryZoomVoronoiContainer = createContainer("zoom", "voronoi") as any;

const DESKTOP_BAR_WIDTH = 5;
const MOBILE_BAR_WIDTH = 20;
const CHART_TOP_PADDING = 10;
const CHART_BOTTOM_PADDING = 30;

const getChartHeight = (numberOfRows: number, isBaseSize: boolean = false) => {
  return (
    numberOfRows * (isBaseSize ? MOBILE_BAR_WIDTH * 2 : DESKTOP_BAR_WIDTH * 3) +
    CHART_TOP_PADDING +
    CHART_BOTTOM_PADDING
  );
};

const BarChart = ({ isLoading, reports }: BarChartProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { agents } = useAppSelector((state) => state.agents);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const metricOptions: MetricOption[] = [
    {
      value: "uniqueConversations",
      label: "Unique Conversations",
    },
    {
      value: "messagesSent",
      label: "Messages Sent",
    },
    {
      value: "avgTimeToReplyInSeconds",
      label: "Average Time to Reply",
    },
  ];

  const [selectedMetric, setSelectedMetric] = useState<
    SingleValue<MetricOption>
  >(metricOptions[0]);
  const [chartData, setChartData] = useState<ChartData[]>(
    reports
      .filter((report) => {
        return (
          report[selectedMetric!.value as keyof ReportPerAgentDomain] !== null
        );
      })
      .map((report) => {
        const agent = agents.find((a) => a.id === report.agentId);
        const agentIndex = agents.indexOf(agent!);
        return {
          agent_number: String(agentIndex + 1),
          metric_value:
            report[selectedMetric!.value as keyof ReportPerAgentDomain],
        };
      })
  );

  useEffect(() => {
    setChartData(
      reports
        .filter((report) => {
          return (
            report[selectedMetric!.value as keyof ReportPerAgentDomain] !== null
          );
        })
        .map((report) => {
          const agent = agents.find((a) => a.id === report.agentId);
          const agentIndex = agents.indexOf(agent!);
          return {
            agent_number: String(agentIndex + 1),
            metric_value:
              report[selectedMetric!.value as keyof ReportPerAgentDomain],
          };
        })
    );
  }, [reports, selectedMetric, agents]);

  return isLoading ? (
    <Flex justifyContent="center" alignItems="center" w="100%" h="100%">
      <Spinner size="xl" />
    </Flex>
  ) : (
    <>
      {isBaseSize ? <Divider /> : null}
      <Box w="100%" minHeight="100%" height="100%" overflowY="auto">
        <VStack w="100%" pt={4} px={isBaseSize ? 4 : 0}>
          <Select
            id="event-form-channel-notification-dropdown"
            placeholder="Select Metric"
            isClearable={false}
            value={selectedMetric}
            onChange={(newValue) => {
              if (newValue) {
                setSelectedMetric(newValue);
              }
            }}
            options={metricOptions}
            styles={{
              ...{
                ...getReactSelectStyles(colorMode, colorScheme),
                container: (provided: any) => ({
                  ...provided,
                  width: isBaseSize ? "100%" : "auto",
                }),
              },
            }}
          />
          {chartData.length ? (
            <svg
              viewBox={`0 0 300 ${getChartHeight(
                chartData.length,
                isBaseSize
              )}`}
              preserveAspectRatio="none"
              width="100%"
            >
              <VictoryChart
                domainPadding={{ x: 10, y: 10 }}
                containerComponent={
                  <VictoryZoomVoronoiContainer
                    allowZoom={false}
                    responsive={false}
                    zoomDimension="x"
                    allowPan={false}
                    zoomDomain={{
                      x: [0, chartData.length],
                      y: [
                        0,
                        Math.max(...chartData.map((d) => d.metric_value || 0)),
                      ],
                    }}
                  />
                }
                theme={VictoryTheme.material}
                standalone={false}
                width={300}
                height={getChartHeight(chartData.length, isBaseSize)}
                padding={{
                  top: CHART_TOP_PADDING,
                  bottom: CHART_BOTTOM_PADDING,
                  left: 50,
                  right: 25,
                }}
              >
                <VictoryAxis
                  dependentAxis
                  tickFormat={(x: any) => {
                    if (!Number.isInteger(x)) {
                      return "";
                    }

                    switch (selectedMetric?.value) {
                      case "uniqueConversations":
                        return x;
                      case "messagesSent":
                        return x;
                      case "avgTimeToReplyInSeconds":
                        return convertSecondsToHoursMinutesSeconds(x);
                      default:
                        return x;
                    }
                  }}
                  minDomain={0}
                  style={{
                    tickLabels: {
                      fontSize: isBaseSize ? "10px" : "5px",
                    },
                  }}
                />
                <VictoryAxis
                  minDomain={0}
                  tickCount={chartData.length}
                  tickLabelComponent={<CustomTickLabelComponent />}
                />
                <VictoryBar
                  horizontal={true}
                  barWidth={isBaseSize ? MOBILE_BAR_WIDTH : DESKTOP_BAR_WIDTH}
                  data={chartData}
                  x="agent_number"
                  y="metric_value"
                  sortKey="metric_value"
                  sortOrder={
                    selectedMetric?.value === "avgTimeToReplyInSeconds"
                      ? "descending"
                      : "ascending"
                  }
                  labels={({ datum }) => {
                    if (datum._y === 0) {
                      return;
                    }

                    switch (selectedMetric?.value) {
                      case "uniqueConversations":
                        return datum.metric_value || 0;
                      case "messagesSent":
                        return datum.metric_value || 0;
                      case "avgTimeToReplyInSeconds":
                        return convertSecondsToHoursMinutesSeconds(
                          datum.metric_value || 0
                        );
                      default:
                        return datum.metric_value || 0;
                    }
                  }}
                  labelComponent={
                    <VictoryTooltip
                      style={{
                        fontSize: isBaseSize ? "10px" : "5px",
                      }}
                      flyoutHeight={isBaseSize ? 20 : 10}
                      pointerWidth={5}
                      pointerLength={5}
                      cornerRadius={2}
                      flyoutStyle={{
                        stroke: "transparent",
                      }}
                    />
                  }
                  dataComponent={<CustomBarComponent />}
                />
              </VictoryChart>
            </svg>
          ) : (
            <VStack w="100%" h="100%">
              <Divider my={4} />
              <Text fontSize="3xl">😱</Text>
              <Text fontWeight="bold">No data available</Text>
            </VStack>
          )}
        </VStack>
      </Box>
    </>
  );
};

export default BarChart;
