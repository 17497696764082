import { ExternalLinkIcon, PhoneIcon } from "@chakra-ui/icons";
import {
  Button,
  Icon,
  Tooltip,
  VStack,
  useBreakpointValue,
  useColorMode,
} from "@chakra-ui/react";
import { ReactComponent as ReplyIcon } from "assets/icons/reply-svgrepo-com.svg";
import {
  MessageButton,
  MessageButtonType,
} from "entities/domain/conversations/message-domain";
import React from "react";
import { useAppSelector } from "redux/hooks";

interface AcitonButtonsProps {}

const ActionButtons: React.FC<AcitonButtonsProps> = () => {
  const { colorMode } = useColorMode();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const {
    messageInput: { template },
  } = useAppSelector((state) => state.conversations);
  return !template ||
    !template.buttons ||
    template.buttons.length === 0 ? null : (
    <VStack
      my={2}
      pl={2}
      width="100%"
      spacing={1}
      alignItems="start"
      flexGrow={0}
    >
      {template.buttons.map((button, index) => (
        <Tooltip
          key={index}
          label={
            button.type === MessageButtonType.PHONE_NUMBER
              ? button.phoneNumber
              : button.type === MessageButtonType.URL
              ? button.url
              : ""
          }
          isDisabled={button.type === MessageButtonType.QUICK_REPLY}
        >
          <Button
            zIndex={5} // To prevent the button from being covered by the tail
            width={isBaseSize ? "100%" : "auto"}
            variant={colorMode === "light" ? "ghost" : "solid"}
            display="flex"
            size="xs"
            textTransform="none"
            alignItems="center"
            justifyContent="start"
            colorScheme={colorMode === "light" ? "blackAlpha" : "whiteAlpha"}
            leftIcon={
              <Icon
                as={
                  button.type === MessageButtonType.QUICK_REPLY
                    ? ReplyIcon
                    : button.type === MessageButtonType.PHONE_NUMBER
                    ? PhoneIcon
                    : ExternalLinkIcon
                }
                __css={
                  button.type === MessageButtonType.QUICK_REPLY
                    ? {
                        path: {
                          fill:
                            colorMode === "light"
                              ? "blackAlpha.600"
                              : "gray.800",
                        },
                      }
                    : {}
                }
              />
            }
          >
            {button.text}
          </Button>
        </Tooltip>
      ))}
    </VStack>
  );
};

export default ActionButtons;
