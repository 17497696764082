import { Box, Button, ButtonGroup, HStack, Textarea } from "@chakra-ui/react";
import WhatsappMarkdownToolbar from "components/user-settings/shared/WhatsappMarkdownToolbar";
import React, { useCallback, useRef } from "react";
import BuildingBlockHeaderWrapper from "./BuildingBlockHeaderWrapper";
import BuildingBlockWrapper from "./BuildingBlockWrapper";
import TextLimits from "./TextLimits";
import Toolbar from "./Toolbar";

interface BodyBuildingBlockProps {
  text: string;
  isDisabled: boolean;
  customFields: { [key: string]: string };
  addNewCustomField: (key: string, value: string) => void;
  onTextChange: React.Dispatch<React.SetStateAction<string>>;
}

const MAX_BODY_LENGTH = 1024;

const BodyBuildingBlock = ({
  text,
  isDisabled,
  customFields,
  addNewCustomField,
  onTextChange,
}: BodyBuildingBlockProps) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const insertEmojiIntoText = useCallback(
    ({ native }: { native: string }) => {
      insertIntoText(native);
    },
    [textAreaRef]
  );

  const insertIntoText = useCallback(
    (someText: string) => {
      if (!textAreaRef || !textAreaRef.current) {
        return;
      }

      const cursorPosition = textAreaRef.current.selectionStart;

      onTextChange((oldText) => {
        const newText =
          oldText.substring(0, cursorPosition) +
          someText +
          oldText.substring(cursorPosition);

        return newText;
      });

      textAreaRef.current.focus();
    },
    [textAreaRef]
  );

  return (
    <BuildingBlockWrapper>
      <BuildingBlockHeaderWrapper name="Body" isOpen={true}>
        <Toolbar
          isDisabled={isDisabled}
          customFields={customFields}
          addNewCustomField={addNewCustomField}
          onEmojiSelect={insertEmojiIntoText}
          onCustomFieldSelect={insertIntoText}
        />
        <WhatsappMarkdownToolbar textAreaRef={textAreaRef} setText={onTextChange} />
      </BuildingBlockHeaderWrapper>
      <Box width="100%" pt="1rem" pb="2rem" px="1.5rem" overflowY="auto">
        <Textarea
          ref={textAreaRef}
          border="none"
          p={0}
          id="whatsapp-template-body-textarea"
          isDisabled={isDisabled}
          placeholder="e.g. We have discounts on some of our products!"
          value={text}
          onChange={(e) => {
            onTextChange(e.target.value);
          }}
          maxLength={MAX_BODY_LENGTH}
        />
      </Box>
      <TextLimits maxCharacters={MAX_BODY_LENGTH} text={text} />
    </BuildingBlockWrapper>
  );
};

export default BodyBuildingBlock;
