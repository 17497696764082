/* eslint-disable import/prefer-default-export */
import { parseISO } from "date-fns";
import ConversationDomain, {
  AllowedMessageType,
  ConversationChannel,
} from "entities/domain/conversations/conversation-domain";
import { MessageStatus } from "entities/domain/conversations/message-domain";
import { ConversationDTO } from "entities/dto/ConversationDTO";

export const conversationTransformFromDtoToDomain = (
  c: ConversationDTO
): ConversationDomain =>
  new ConversationDomain(
    c.id,
    c.merchant_id,
    Number(c.customer.id),
    c.customer.full_name,
    c.customer.avatar || "",
    c.customer.channel_id,
    c.alias,
    c.channel
      ? (c.channel as ConversationChannel)
      : ConversationChannel.NO_CHANNEL,
    c.is_open,
    c.created_at,
    c.unread_count,
    c.assigned_agent_id,
    c.assigned_team_id,
    c.is_subscribed,
    c.message?.body,
    c.message?.status as MessageStatus,
    c.message?.created_at
      ? parseISO(
          c.message.created_at.includes("Z")
            ? c.message.created_at
            : `${c.message.created_at}Z`
        )
      : parseISO(
          c.created_at.includes("Z") ? c.created_at : `${c.created_at}Z`
        ),
    c.message?.id,
    c.allowed_messages_type === "all"
      ? AllowedMessageType.ALL
      : AllowedMessageType.TEMPLATES_ONLY,
    c.customer.tag_ids || [],
    c.customer.name,
    c.customer.surname,
    c.message?.title,
    c.message?.is_incoming
  );

export const conversationsTransformFromDtoToDomain = (
  conversations: ConversationDTO[]
): ConversationDomain[] =>
  conversations.map(conversationTransformFromDtoToDomain);
