import { HStack, Text, useColorMode } from "@chakra-ui/react";
import TemplateDomain from "entities/domain/templates";
import useAvailableCustomFields from "hooks/use-available-custom-fields";
import React, { useMemo } from "react";
import { useAppSelector } from "redux/hooks";

interface EmptyCustomFieldsWarningProps {
  text: string;
}

const EmptyCustomFieldsWarning: React.FC<EmptyCustomFieldsWarningProps> = ({
  text,
}) => {
  const { colorMode } = useColorMode();
  const {
    messageInput: { template },
  } = useAppSelector((state) => state.conversations);
  const { availableCustomFields } = useAvailableCustomFields(template);

  const missingCustomFieldsTotal = useMemo(() => {
    if (!template) {
      return 0;
    }

    return TemplateDomain.getCustomFieldsWithoutValue(
      text,
      availableCustomFields
    ).length;
  }, [text, availableCustomFields]);

  return !missingCustomFieldsTotal || !template ? null : (
    <HStack
      w="100%"
      h="100%"
      wrap="wrap"
      pl={16}
      pr={8}
      position="absolute"
      alignItems="center"
      justifyContent="center"
      zIndex={0}
    >
      <Text
        alignSelf="center"
        color={`red.${colorMode === "dark" ? "200" : "500"}`}
        fontWeight="bold"
      >
        Provide missing values
      </Text>
    </HStack>
  );
};

export default EmptyCustomFieldsWarning;
