import { useAuth0 } from "@auth0/auth0-react";
import SwitchAccount from "components/switch-account";
import MerchantDomainBase from "entities/domain/admin/merchants/merchant-domain";
import React, { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { setCurrentAgent } from "redux/features/agents";
import {
  setIsSelectorOpened,
  updateMerchantSuccess,
} from "redux/features/merchant";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import AgentsService from "services/agents";
import MerchantService from "services/merchant";
import PrivateRoute from "./PrivateRoute";

const AbstractPrivateRoute = ({ children }: { children?: ReactNode }) => {
  const Url = useLocation();
  const auth0Context = useAuth0();
  const { isAuthenticated } = auth0Context;
  const { currentAgent } = useAppSelector((state) => state.agents);
  const dispatch = useAppDispatch();
  const { isSelectorOpened } = useAppSelector((state) => state.merchant);

  const getCurrentMerchant = async (
    id: number
  ): Promise<MerchantDomainBase | null> => {
    try {
      const merchantResponse = await MerchantService.getMerchant(
        auth0Context,
        id
      );

      return merchantResponse;
    } catch (err) {
      // eslint-disable-next-line
      console.error("Error fetching merchant", err);
    }

    return null;
  };

  useEffect(() => {
    if (Url.pathname.includes("public")) {
      return;
    }

    AgentsService.getCurrentAgent(auth0Context).then((agent) => {
      if (!agent) {
        return;
      }

      dispatch(setCurrentAgent(agent));

      const availableMerchants = agent.merchants.map((m) => m.id);
      const selectedMerchantId = localStorage.getItem("fuzey:merchant:id");

      const merchantFromLocalStorageAvailable = selectedMerchantId
        ? availableMerchants.includes(parseInt(selectedMerchantId, 10))
        : false;

      const unknownRoute =
        selectedMerchantId && Url.pathname.includes(selectedMerchantId);

      if (
        (Url.pathname.includes("callback") ||
          Url.pathname.includes("xero") ||
          Url.pathname.includes("quickbooks") ||
          Url.pathname.includes("reviews/google/locations") ||
          unknownRoute) &&
        merchantFromLocalStorageAvailable
      ) {
        getCurrentMerchant(parseInt(selectedMerchantId!, 10)).then(
          (merchantResponse) => {
            if (merchantResponse) {
              dispatch(updateMerchantSuccess(merchantResponse));
            }
          }
        );

        return;
      }

      if (merchantFromLocalStorageAvailable) {
        window.location.href = `/${selectedMerchantId}${Url.pathname}${Url.search}`;

        return;
      }

      if (agent.merchants.length === 1) {
        const firstMerchant = agent.merchants[0];
        localStorage.setItem("fuzey:merchant:id", firstMerchant.id.toString());
        window.location.href = `/${firstMerchant.id}${Url.pathname}${Url.search}`;

        return;
      }

      dispatch(setIsSelectorOpened(true));
    });
  }, [isAuthenticated]);

  return (
    <>
      {isSelectorOpened ? null : <PrivateRoute>{children}</PrivateRoute>}
      <SwitchAccount
        isRequired={true}
        merchants={currentAgent?.merchants || []}
      />
    </>
  );
};

export default AbstractPrivateRoute;
