import TemplateDomain from "entities/domain/templates";
import { useMemo } from "react";
import { useAppSelector } from "redux/hooks";

export default function useAvailableCustomFields(
  template: TemplateDomain | null
) {
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { activeConversation } = useAppSelector((state) => state.conversations);
  const { merchant } = useAppSelector((state) => state.merchant);

  const availableCustomFields = useMemo(
    () => ({
      contact_first_name: activeConversation?.customerFirstName || "",
      contact_last_name: activeConversation?.customerLastName || "",
      agent_first_name: currentAgent?.name || "",
      agent_last_name: currentAgent?.surname || "",
      agent_name:
        currentAgent?.name && currentAgent?.surname
          ? `${currentAgent.name} ${currentAgent.surname}`
          : "",
      agent_phone: currentAgent?.phone || "",
      agent_email: currentAgent?.email || "",
      merchant_name: merchant.name || "",
      ...(template?.customFields || {}),
    }),
    [currentAgent, activeConversation, template]
  );

  return { availableCustomFields };
}
