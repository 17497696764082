import { Box, Flex, Icon, MenuItem, useColorMode } from "@chakra-ui/react";
import { ReactComponent as MailIcon } from "assets/icons/Mail-ds.svg";
import { ReactComponent as Bullhorn } from "assets/icons/bullhorn-regular.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar-2.svg";
import { ReactComponent as CreditCard } from "assets/icons/credit-card-2.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/fi-rr-settings.svg";
import { ReactComponent as ReviewsIcon } from "assets/icons/product-starred-svgrepo-com.svg";
import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { FaChartArea } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

interface MobileBrowserNavigationButtonProps {
  icon: any;
  name: string;
  link: string;
  hasNotifications?: boolean;
  isDisabled?: boolean;
}

const MobileBrowserNavigationButton = ({
  icon,
  hasNotifications = false,
  name,
  link,
  isDisabled,
}: MobileBrowserNavigationButtonProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <Box
      sx={{
        "a.selected > button": {
          bgColor: colorMode === "dark" ? `gray.800` : `${colorScheme}.400`,
        },
        "> a.selected path": {
          fill: "white",
        },
      }}
    >
      <NavLink
        to={link}
        className={({ isActive }) => (isActive ? "selected" : "")}
      >
        <MenuItem
          icon={
            <Flex
              position="relative"
              alignItems="center"
              justifyContent="center"
            >
              <Icon
                as={icon}
                width="2rem"
                height="2rem"
                __css={{
                  path: {
                    fill: colorMode === "dark" ? "white" : "gray.300",
                  },
                  rect: {
                    fill: "transparent",
                  },
                }}
              />
              {hasNotifications ? (
                <Box
                  position="absolute"
                  top="-0.25rem"
                  right="-0.25rem"
                  zIndex="1"
                  borderRadius="full"
                  height="1rem"
                  width="1rem"
                  bgColor={colorMode === "dark" ? "red.200" : "red.500"}
                />
              ) : null}
            </Flex>
          }
          isDisabled={isDisabled}
        >
          {name}
        </MenuItem>
      </NavLink>
    </Box>
  );
};

export const InboxLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { unreadCounts } = useAppSelector((state) => state.agents);
  const unreadCount = unreadCounts.find((uc) => uc.merchant_id === merchant.id);

  return (
    <MobileBrowserNavigationButton
      hasNotifications={!!unreadCount?.personal || !!unreadCount?.shared}
      icon={MailIcon}
      name="Inbox"
      link={`/${merchant.id}/inbox`}
    />
  );
};

export const ContactsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <MobileBrowserNavigationButton
      icon={AiOutlineUser}
      name="Contacts"
      link={`/${merchant.id}/contacts`}
    />
  );
};

export const CalendarLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <MobileBrowserNavigationButton
      icon={CalendarIcon}
      name="Calendar"
      link={`/${merchant.id}/calendar`}
    />
  );
};

export const PaymentsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <MobileBrowserNavigationButton
      icon={CreditCard}
      name="Payments"
      link={`/${merchant.id}/payments`}
    />
  );
};

export const CampaignsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <MobileBrowserNavigationButton
      icon={Bullhorn}
      name="Campaigns"
      link={`/${merchant.id}/campaigns`}
    />
  );
};

export const ReviewsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <MobileBrowserNavigationButton
      icon={ReviewsIcon}
      name="Reviews"
      link={`/${merchant.id}/reviews`}
    />
  );
};

export const AnalyticsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <MobileBrowserNavigationButton
      icon={FaChartArea}
      name="Analytics"
      link={`/${merchant.id}/analytics`}
    />
  );
};

export const SettingsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <MobileBrowserNavigationButton
      icon={SettingsIcon}
      name="Settings"
      link={`/${merchant.id}/settings`}
    />
  );
};

export default MobileBrowserNavigationButton;
