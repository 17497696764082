import { Icon, IconButton, useColorMode } from "@chakra-ui/react";
import { ReactComponent as TrashIcon } from "assets/icons/bin-ds.svg";
import React from "react";
import { clearMessageInput } from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";

interface ClearInputButtonProps {
  onClick: () => void;
  isDisabled: boolean;
}

const ClearInputButton: React.FC<ClearInputButtonProps> = ({
  onClick,
  isDisabled,
}) => {
  const dispatch = useAppDispatch();
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <IconButton
      aria-label="Clear input"
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "> svg": {
          transition: "width 0.2s, height 0.2s",
          height: "1rem",
          width: "1rem",
        },
        ":hover > svg": {
          width: isDisabled ? "1rem" : "0.8rem",
          height: isDisabled ? "1rem" : "0.8rem",
        },
      }}
      icon={
        <Icon
          as={TrashIcon}
          __css={{
            path: {
              fill:
                colorMode === "dark"
                  ? `${colorScheme}.200`
                  : `${colorScheme}.400`,
            },
          }}
        />
      }
      onClick={() => {
        dispatch(clearMessageInput());
        onClick();
      }}
      size="xs"
      colorScheme={colorScheme}
      variant="ghost"
    />
  );
};

export default ClearInputButton;
