import { AxiosError } from "axios";
import DomainError from "./domain";

export const axiosErrorDataToDomainError = (
  err: AxiosError<Record<string, any>> | null
): DomainError | null => {
  if (!err || !err.response || !err.response.data) {
    return null;
  }

  const { code, message } = err.response.data;

  if (code === "insufficient_limit") {
    return new DomainError(
      code,
      message,
      "You have reached the limit of WhatsApp initiated conversations. Please upgrade to continue messaging.",
      {}
    );
  }

  return new DomainError(
    code || "default_error",
    message !== null ? message : "An Error Occurred",
    null,
    {}
  );
};
