import {
  useBreakpointValue,
  Box,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorMode,
  Flex,
} from "@chakra-ui/react";
import { useAppSelector } from "redux/hooks";
import React, { memo, useCallback, useEffect, useState } from "react";
import { getChannelIcon } from "util/constants";
import { ReactSVG } from "react-svg";
import { CampaignStatus } from "entities/domain/campaign";
import useTemplatesStore from "hooks/use-templates-store";
import { ConversationChannel } from "entities/domain/conversations/conversation-domain";
import {
  containsPrefix,
  containsSuffix,
  removePrefixAndSuffix,
  replaceCustomFields,
} from "util/methods";
import Preview from "components/user-settings/TemplateSettings/create-whatsapp-template/Preview";
import { RegularOrWhatsappTemplate } from "redux/features/templates";
import { AppleIPhone } from "@kazimirchuk/react-device-preview";
import { defaultCustomFieldsDefinition } from "components/user-settings/TemplateSettings/create-whatsapp-template/utils";
import FileDomain from "entities/domain/file";
import SmsMessageTabPanel from "./SmsMessageTabPanel";
import WhatsappMessageTabPanel from "./WhatsappMessageTabPanel";

interface MessageProps {
  attachedFile: FileDomain | null;
  prefix?: string;
  campaignChannel: ConversationChannel;
  campaignMessage: string | null;
  campaignStatus: CampaignStatus;
  campaignTemplateName: string | null;
  campaignMediaUrl: string | null;
  campaignMediaType: string | null;
  isIncludingSuffix?: boolean;
  onAttachmentRemove: () => void;
  setPrefix?: (newPrefix: string) => void;
  setCampaignTemplateName: (tid: string | null) => void;
  setAttachedFile: (file: FileDomain | null) => void;
  setIsValid: (isValid: boolean) => void;
  setIsIncludingSuffix?: (newIsIncluded: boolean) => void;
  setCampaignMessage: (message: string | null) => void;
}

const Message = ({
  attachedFile,
  campaignChannel,
  campaignMediaType,
  campaignMediaUrl,
  campaignStatus,
  campaignTemplateName,
  prefix,
  isIncludingSuffix,
  campaignMessage,
  setCampaignTemplateName,
  onAttachmentRemove,
  setCampaignMessage,
  setPrefix,
  setIsValid,
  setAttachedFile,
  setIsIncludingSuffix,
}: MessageProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const { fetchTemplates } = useTemplatesStore();
  const { templates } = useAppSelector((state) => state.templates);
  const { currentAgent } = useAppSelector((state) => state.agents);
  const { merchant } = useAppSelector((state) => state.merchant);
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );

  const [messagingChannels, setMessagingChannels] = useState<
    ConversationChannel[]
  >([campaignChannel]);

  const optionalSuffix = `\nReply UNSUB to unsubscribe`;

  useEffect(() => {
    setMessagingChannels([campaignChannel]);
  }, [campaignChannel]);

  const [activeTab, setActiveTab] = useState<number>(0);
  const [localMessage, setLocalMessage] = useState<string>(
    campaignMessage
      ? removePrefixAndSuffix(campaignMessage, prefix!, optionalSuffix)
      : ""
  );

  const memoizedSetMessage = useCallback(
    (message: string) => {
      setLocalMessage(message);
    },
    [setLocalMessage]
  );

  const [templateAttachmentUrl, setTemplateAttachmentUrl] = useState<
    string | null
  >(null);
  const [templateAttachmentType, setTemplateAttachmentType] = useState<
    string | null
  >(null);

  const addPrefixIfNeeded = (text: string) =>
    containsPrefix(text, prefix!) ? text : `${prefix} ${text}`;
  const addSuffixIfNeeded = (text: string) =>
    containsSuffix(text, optionalSuffix) ? text : `${text}${optionalSuffix}`;

  const [customFields, setCustomFields] = useState<{ [key: string]: string }>(
    defaultCustomFieldsDefinition
  );

  useEffect(() => {
    setCustomFields((prev) => ({
      ...prev,
      contact_first_name: currentAgent?.name || "Alex",
      contact_last_name: currentAgent?.surname || "Boyce",
      agent_first_name: currentAgent?.name || "Alex",
      agent_last_name: currentAgent?.surname || "Boyce",
      agent_name:
        currentAgent?.name && currentAgent?.surname
          ? `${currentAgent.name} ${currentAgent.surname}`
          : "Alex Boyce",
      agent_email: currentAgent?.email || "alexboyce@fuzey.com",
      agent_phone: currentAgent?.phone || "1234567890",
      merchant_name: merchant.name || "Fuzey",
      review_link: "https://random.link",
    }));
  }, [currentAgent]);

  useEffect(() => {
    if ((campaignMessage && localMessage) || campaignTemplateName) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [campaignMessage, campaignTemplateName]);

  useEffect(() => {
    setCampaignMessage(
      isIncludingSuffix
        ? addSuffixIfNeeded(addPrefixIfNeeded(localMessage))
        : addPrefixIfNeeded(localMessage)
    );
  }, [localMessage, isIncludingSuffix, prefix]);

  useEffect(() => {
    fetchTemplates(undefined, campaignChannel === ConversationChannel.SMS);
  }, [campaignChannel]);

  const [previewText, setPreviewText] = useState<string>("");

  useEffect(() => {
    if (campaignTemplateName) {
      const template = templates.find((t) => t.name === campaignTemplateName);

      let endText =
        replaceCustomFields(
          template?.getWholeText() || "",
          template?.customFields || customFields
        ) || "";

      if (
        campaignChannel === ConversationChannel.SMS &&
        merchant.country !== "US"
      ) {
        if (template && template.getMedia()) {
          endText = `${endText}\nhttp://link.to/your/file`;
        }

        setLocalMessage("");
        setPreviewText(endText);
        setTemplateAttachmentType(null);
        setTemplateAttachmentUrl(null);
      } else {
        setPreviewText(endText);
        const media = template?.getMedia();
        setTemplateAttachmentType(media?.type || null);
        setTemplateAttachmentUrl(media?.url || null);
      }
    } else {
      const messageBodyWithoutPrefixAndSuffix = campaignMessage
        ? removePrefixAndSuffix(campaignMessage, prefix!, optionalSuffix)
        : "";
      const hasMessageBody = replaceCustomFields(
        messageBodyWithoutPrefixAndSuffix,
        customFields
      );
      let endText =
        replaceCustomFields(campaignMessage || "", customFields) || "";

      if (
        campaignChannel === ConversationChannel.SMS &&
        merchant.country !== "US" &&
        ((campaignMediaType && campaignMediaUrl) || attachedFile)
      ) {
        endText = `${endText}\nhttp://link.to/your/file`;
      }

      setPreviewText(hasMessageBody ? endText : "");
      setTemplateAttachmentType(null);
      setTemplateAttachmentUrl(null);
    }
  }, [campaignTemplateName, templates, campaignMessage, attachedFile]);

  const [currentTemplate, setCurrentTemplate] =
    useState<RegularOrWhatsappTemplate | null>(null);

  useEffect(() => {
    if (campaignTemplateName) {
      const template = templates.find((t) => t.name === campaignTemplateName);
      setCurrentTemplate(template || null);
    } else {
      setCurrentTemplate(null);
    }
  }, [campaignTemplateName]);

  return (
    <Tabs
      colorScheme={colorScheme}
      display="flex"
      flexDirection="column"
      overflow="hidden"
      height="100%"
      index={activeTab}
      onChange={(index) => {
        setActiveTab(index);
      }}
      isFitted
    >
      <TabList
        {...(messagingChannels.length === 1
          ? {
              display: "none",
            }
          : {})}
      >
        {messagingChannels.map((c: ConversationChannel) => (
          <Tab key={c}>
            <Icon
              as={ReactSVG}
              src={getChannelIcon(c)}
              __css={{
                height: "1.5rem",
                width: "1.5rem",
              }}
            />
          </Tab>
        ))}
      </TabList>
      <TabPanels h="100%" display="flex" overflow="hidden">
        {messagingChannels.map((c: ConversationChannel, index: number) => (
          <TabPanel
            key={`${c}-${index}-tab-panel`}
            display="flex"
            w="100%"
            height="100%"
            mx="auto"
            my={0}
            py={10}
            h="100%"
            overflowY="auto"
            gridGap={8}
            px={isBaseSize ? 4 : 4}
          >
            <Box
              {...(c === ConversationChannel.SMS
                ? {
                    flexGrow: 5,
                    flexBasis: "auto",
                    flexShrink: 1,
                  }
                : {
                    w: "100%",
                  })}
            >
              {c === ConversationChannel.SMS && (
                <SmsMessageTabPanel
                  setCampaignTemplateName={setCampaignTemplateName}
                  onAttachmentRemove={onAttachmentRemove}
                  attachedFile={attachedFile}
                  setAttachedFile={setAttachedFile}
                  templateName={campaignTemplateName}
                  campaignMediaType={campaignMediaType}
                  campaignMediaUrl={campaignMediaUrl}
                  campaignStatus={campaignStatus}
                  message={localMessage}
                  setMessage={memoizedSetMessage}
                  customFields={customFields}
                  prefix={prefix!}
                  setPrefix={setPrefix!}
                  isIncludingSuffix={isIncludingSuffix!}
                  setIsIncludingSuffix={setIsIncludingSuffix!}
                />
              )}
              {c === ConversationChannel.WHATSAPP && (
                <WhatsappMessageTabPanel
                  campaignTemplateName={campaignTemplateName}
                  campaignStatus={campaignStatus}
                  setCampaignTemplateName={setCampaignTemplateName}
                />
              )}
            </Box>
            {isBaseSize ? null : (
              <Flex
                {...(c === ConversationChannel.SMS
                  ? {
                      flexGrow: 1,
                      flexBasis: "auto",
                      flexShrink: 0,
                    }
                  : {
                      w: "100%",
                    })}
                justifyContent="center"
                py={10}
                px={4}
              >
                {campaignChannel === ConversationChannel.SMS ? (
                  <AppleIPhone
                    colorMode={colorMode}
                    size="sm"
                    app={{
                      name: "BuiltInMessenger",
                      options: {
                        messages: [
                          {
                            text: previewText,
                            mediaUrl:
                              campaignChannel === ConversationChannel.SMS &&
                              merchant.country === "US"
                                ? undefined
                                : templateAttachmentUrl || undefined,
                            mediaType:
                              campaignChannel === ConversationChannel.SMS &&
                              merchant.country === "US"
                                ? undefined
                                : templateAttachmentType || undefined,
                            isOutgoing: true,
                          },
                          ...[
                            (attachedFile ||
                              campaignMediaUrl ||
                              (templateAttachmentType &&
                                templateAttachmentUrl)) &&
                            campaignChannel === ConversationChannel.SMS &&
                            merchant.country === "US"
                              ? {
                                  media: attachedFile || undefined,
                                  mediaType: attachedFile
                                    ? attachedFile.type
                                    : templateAttachmentType ||
                                      campaignMediaType,
                                  mediaUrl: attachedFile
                                    ? undefined
                                    : templateAttachmentUrl ||
                                      campaignMediaUrl ||
                                      undefined,
                                  isOutgoing: true,
                                }
                              : {},
                          ],
                        ],
                      },
                    }}
                  />
                ) : (
                  <Preview
                    customFields={currentTemplate?.customFields || customFields}
                    headerText={
                      (currentTemplate &&
                        currentTemplate.isWhatsappTemplate() &&
                        currentTemplate.getHeaderText()) ||
                      ""
                    }
                    bodyText={
                      (currentTemplate &&
                        currentTemplate.isWhatsappTemplate() &&
                        currentTemplate.getBodyText()) ||
                      ""
                    }
                    footerText={
                      (currentTemplate &&
                        currentTemplate.isWhatsappTemplate() &&
                        currentTemplate.getFooterText()) ||
                      ""
                    }
                    buttons={
                      (currentTemplate &&
                        currentTemplate.isWhatsappTemplate() &&
                        currentTemplate.getButtons()) ||
                      []
                    }
                    file={null}
                    mediaUrl={templateAttachmentUrl || undefined}
                    mediaType={templateAttachmentType || undefined}
                  />
                )}
              </Flex>
            )}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default memo(Message);
