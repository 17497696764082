import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import useMarkFramePaint from "./use-mark-frame-paint";

export const RenderContext = createContext<{
  markRendered: (id: string) => void;
  renderedStates: Record<string, boolean>;
  renderCycleId: number;
}>({
  markRendered: () => {},
  renderedStates: {},
  renderCycleId: 0,
});

function getDefaultStates(waitFor: string[]) {
  return waitFor.reduce((obj, id) => {
    obj[id] = false;
    return obj;
  }, {} as Record<string, boolean>);
}

export function RenderProvider({
  children,
  waitFor,
  resetRef,
  onComplete,
}: {
  children: ReactNode;
  waitFor: string[];
  resetRef?: React.MutableRefObject<() => void>;
  onComplete: () => void;
}) {
  const [renderedStates, setRenderedStates] = useState<Record<string, boolean>>(
    getDefaultStates(waitFor)
  );

  // Track the "render cycle" ID. Whenever `waitFor` changes significantly,
  // we increment this ID. Children can use it to know they should reset or recheck.
  const [renderCycleId, setRenderCycleId] = useState<number>(0);

  // If the `waitFor` array changes, that implies we’re waiting for a new set
  // of child IDs. We reset our flags and bump the render cycle ID.
  useEffect(() => {
    const defaultStates = getDefaultStates(waitFor);
    setRenderedStates((prev) => {
      // Keep any old flags if they still exist in the new waitFor list.
      const nextStates = { ...defaultStates };
      for (const key of Object.keys(prev)) {
        if (key in nextStates) {
          nextStates[key] = prev[key];
        }
      }
      return nextStates;
    });
    setRenderCycleId((cycle) => cycle + 1);
  }, [waitFor]);

  const markRendered = useCallback((id: string) => {
    setRenderedStates((prev) => {
      if (prev[id]) {
        // Already marked as rendered, no change
        return prev;
      }
      return { ...prev, [id]: true };
    });
  }, []);

  const resetStates = useCallback(() => {
    setRenderedStates(getDefaultStates(waitFor));
    setRenderCycleId((cycle) => cycle + 1);
  }, [waitFor]);

  useEffect(() => {
    if (resetRef) {
      resetRef.current = resetStates;
    }
  }, [resetRef, resetStates]);

  // Delay onComplete by one more animation frame to avoid partial re-renders
  useEffect(() => {
    if (Object.keys(renderedStates).length === 0) {
      return;
    }
    const allRendered = Object.values(renderedStates).every((v) => v);
    if (allRendered) {
      const handle = requestAnimationFrame(() => {
        onComplete();
      });
      return () => cancelAnimationFrame(handle);
    }
  }, [renderedStates]); // if we start observing onComplete then it should be memoized

  return (
    <RenderContext.Provider
      value={{
        markRendered,
        renderedStates,
        renderCycleId,
      }}
    >
      {children}
    </RenderContext.Provider>
  );
}

export function useRenderTracker(
  id: string,
  shouldTrack: boolean,
  debounce: number = 0
) {
  const { markRendered, renderedStates, renderCycleId } =
    useContext(RenderContext);

  const timerRef = useRef<number | null>(null);

  const afterPaint = useCallback(() => {
    if (renderedStates[id]) {
      return;
    }

    const timer = window.setTimeout(() => {
      markRendered(id);
      timerRef.current = null;
    }, debounce);

    timerRef.current = timer as unknown as number;

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [id, debounce, markRendered, renderedStates]);

  // If the renderCycleId changes, that means we’re in a new “batch” of items to wait for,
  // so let’s clear any stale timer from the previous cycle.
  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  }, [renderCycleId]);

  // We only run afterPaint if `shouldTrack` is true.
  // useMarkFramePaint will schedule “afterPaint” in an rAF + postMessage chain
  // and clean up properly on unmount.
  useMarkFramePaint(afterPaint, shouldTrack);
}

export function RenderAware({
  id,
  shouldTrack,
  debounce,
  children,
}: {
  id: string;
  shouldTrack: boolean;
  debounce: number;
  children: ReactNode;
}) {
  useRenderTracker(id, shouldTrack, debounce);
  return <>{children}</>;
}
