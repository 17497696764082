import { useAuth0 } from "@auth0/auth0-react";
import ContactDomain from "entities/domain/customers/contact-domain";
import TagsDomain from "entities/domain/tags/tags-domain";
import { useCallback } from "react";
import {
  createContact,
  createContactFail,
  createContactSuccess,
  deleteContact,
  deleteContactFail,
  deleteContactSuccess,
  deleteContactTags,
  deleteContactTagsFail,
  deleteContactTagsSuccess,
  mergeContacts,
  mergeContactsFail,
  mergeContactsSuccess,
  setContacts,
  updateContact,
  updateContactFail,
  updateContactSuccess,
  updateContactTags,
  updateContactTagsFail,
  updateContactTagsSuccess,
} from "redux/features/contacts";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import ContactsService, {
  CreateContactPayload,
  EditContactDetailsPayload,
  MergeContactDetailsPayload,
} from "services/contacts";
import { getErrorDescriptionOrDefault } from "services/errorCodeConverter";
import useConversationsStore from "./use-conversations-store";

export default function useContactsStore() {
  const dispatch = useAppDispatch();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { tags } = useAppSelector((state) => state.tags);
  const { updateConversationsWithCustomerUpdate } = useConversationsStore();
  const auth0Context = useAuth0();

  const getContactTags = useCallback(
    (contact: ContactDomain): TagsDomain[] => {
      return tags.filter((t) => contact.tagIds.includes(t.id));
    },
    [tags]
  );

  const getTagsByName = useCallback(
    (tagNames: string[]): TagsDomain[] => {
      return tags.filter((t) => tagNames.includes(t.tag));
    },
    [tags]
  );

  const updateContactWaterfall =
    (payload: EditContactDetailsPayload) =>
    async (): Promise<ContactDomain | undefined> => {
      try {
        dispatch(updateContact());

        const contactResponse = await ContactsService.updateContact(
          auth0Context,
          payload,
          merchant.groupId
        );
        updateConversationsWithCustomerUpdate(
          contactResponse.id!,
          {
            displayName: contactResponse.fullName,
            picture: contactResponse.avatar || "",
            tagIds: contactResponse.tagIds,
          },
          contactResponse.channels
        );
        dispatch(updateContactSuccess(contactResponse));
        return contactResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't update this contact. Please try again!"
        );
        dispatch(updateContactFail([errorMessage]));
        return undefined;
      }
    };

  const mergeContactsWaterfall =
    (payload: MergeContactDetailsPayload) =>
    async (): Promise<ContactDomain | undefined> => {
      try {
        dispatch(mergeContacts());

        const contactResponse = await ContactsService.mergeContacts(
          auth0Context,
          payload,
          merchant.groupId
        );

        dispatch(
          mergeContactsSuccess({
            contact: contactResponse,
            deletedContacts: payload.contactIds,
          })
        );
        return contactResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't merge these contacts. Please try again!"
        );
        dispatch(mergeContactsFail([errorMessage]));
      }
      return undefined;
    };

  const createContactWaterfall =
    (payload: CreateContactPayload, idempotencyKey: string) =>
    async (): Promise<ContactDomain | undefined> => {
      try {
        dispatch(createContact());

        const contactResponse = await ContactsService.createNewContact(
          auth0Context,
          payload,
          idempotencyKey,
          merchant.groupId
        );

        dispatch(createContactSuccess(contactResponse));
        return contactResponse;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't save this contact. Please try again!"
        );
        dispatch(createContactFail([errorMessage]));
        return undefined;
      }
    };

  const deleteContactTagsWaterfall =
    (tagToDelete: TagsDomain, contactId: number) =>
    async (): Promise<TagsDomain[]> => {
      try {
        dispatch(deleteContactTags());

        const tagNames = await ContactsService.removeTagFromContact(
          auth0Context,
          contactId,
          tagToDelete.tag,
          tagToDelete.id,
          merchant.groupId
        );
        const restOfTheTags = getTagsByName(tagNames);
        const restOfTagsIds = restOfTheTags.map((t) => t.id);
        updateConversationsWithCustomerUpdate(contactId, {
          tagIds: restOfTagsIds,
        });
        dispatch(
          deleteContactTagsSuccess({
            id: contactId,
            tagIds: restOfTagsIds,
          })
        );
        return restOfTheTags;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't delete this tag. Please try again!"
        );
        dispatch(deleteContactTagsFail([errorMessage]));
        return [];
      }
    };

  const deleteContactWaterfall =
    (contactId: number) => async (): Promise<void> => {
      try {
        dispatch(deleteContact());

        await ContactsService.deleteContact(
          auth0Context,
          contactId,
          merchant.groupId
        );

        dispatch(deleteContactSuccess(contactId));
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't delete this contact. Please try again!"
        );
        dispatch(deleteContactFail([errorMessage]));
      }
    };

  const updateContactTagsWaterfall =
    (tagsToAdd: TagsDomain[], contactId: number) =>
    async (): Promise<TagsDomain[] | undefined> => {
      try {
        dispatch(updateContactTags());

        const tagNames = await ContactsService.addTagToContact(
          auth0Context,
          contactId,
          tagsToAdd.map((t) => t.tag),
          tagsToAdd.map((t) => t.id),
          merchant.groupId
        );
        const updatedTags = getTagsByName(tagNames);
        const updatedTagIds = updatedTags.map((t) => t.id);

        updateConversationsWithCustomerUpdate(contactId, {
          tagIds: updatedTagIds,
        });
        dispatch(
          updateContactTagsSuccess({
            id: contactId,
            tagIds: updatedTagIds,
          })
        );
        return updatedTags;
      } catch (err: any) {
        const errorMessage = getErrorDescriptionOrDefault(
          err.response?.data?.code,
          "Oops. We couldn't add this tag. Please try again!"
        );
        dispatch(updateContactTagsFail([errorMessage]));
        return undefined;
      }
    };

  const editContactAsync = useCallback(
    (payload: EditContactDetailsPayload) => updateContactWaterfall(payload)(),
    [dispatch, merchant]
  );

  const deleteContactAsync = useCallback(
    (contactId: number) => deleteContactWaterfall(contactId)(),
    [dispatch, merchant]
  );

  const mergeContactAsync = useCallback(
    (payload: MergeContactDetailsPayload) => mergeContactsWaterfall(payload)(),
    [dispatch, merchant]
  );

  const createContactAsync = useCallback(
    (payload: CreateContactPayload, idempotencyKey: string) =>
      createContactWaterfall(payload, idempotencyKey)(),
    [dispatch, merchant]
  );

  const deleteContactTagAsync = useCallback(
    (tagToDelete: TagsDomain, contactId: number) =>
      deleteContactTagsWaterfall(tagToDelete, contactId)(),
    [dispatch, merchant]
  );

  const updateContactTagsAsync = useCallback(
    (tagsToAdd: TagsDomain[], contactId: number) =>
      updateContactTagsWaterfall(tagsToAdd, contactId)(),
    [dispatch, merchant]
  );

  const setContactsAsync = useCallback(
    (contacts: ContactDomain[]) => dispatch(setContacts(contacts)),
    [dispatch]
  );

  return {
    setContacts: setContactsAsync,
    deleteContact: deleteContactAsync,
    editContact: editContactAsync,
    mergeContact: mergeContactAsync,
    createContact: createContactAsync,
    deleteContactTag: deleteContactTagAsync,
    updateContactTags: updateContactTagsAsync,
    getContactTags,
  };
}
