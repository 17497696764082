import { useAuth0 } from "@auth0/auth0-react";
import { Box, Flex, HStack, useBreakpointValue } from "@chakra-ui/react";
import MerchantSwitch from "components/merchant-switch/desktop";
import NavigationBar from "components/navigation-bar";
import {
  BrowserNotificationEvent,
  SocketConversationUnreadCountChanged,
} from "entities/ISocketArgs";
import { useWebSocket } from "hooks/use-socket";
import React, { useEffect, useState } from "react";
import { setUnreadCounts } from "redux/features/agents";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import InboxService from "services/inbox";
import {
  sendBrowserNotification,
  updateBadge,
} from "util/browser_notifications";
import PageBanner from "./PageBanner";

interface PrivatePageWrapperProps {
  children: React.ReactNode;
}

const PrivatePageWrapper = ({ children }: PrivatePageWrapperProps) => {
  const auth0Context = useAuth0();
  const dispatch = useAppDispatch();
  const isBaseSize = useBreakpointValue(
    { base: true, md: false },
    { ssr: false }
  );
  const { merchant } = useAppSelector((state) => state.merchant);
  const { addEventHandler, removeEventHandler } = useWebSocket();
  const { currentAgent, unreadCounts } = useAppSelector(
    (state) => state.agents
  );

  const [conversationUnreadCountChanged, setConversationUnreadCountChanged] =
    useState({} as SocketConversationUnreadCountChanged);

  useEffect(() => {
    InboxService.getUnreadCounts(auth0Context).then((newUnreadCounts) => {
      dispatch(setUnreadCounts(newUnreadCounts));
    });
  }, []);

  useEffect(() => {
    const unreadCount = unreadCounts.find(
      (uc) => uc.merchant_id === merchant.id
    );

    updateBadge(unreadCount?.shared || 0);
  }, [unreadCounts]);

  const handleConversationUnreadCountChanged = (
    args: SocketConversationUnreadCountChanged
  ) => {
    const merchantIdsAgentBelongsTo = currentAgent?.merchants.map((m) => m.id);

    if (!merchantIdsAgentBelongsTo?.includes(args.unread_count.merchant_id)) {
      return;
    }

    setConversationUnreadCountChanged(args);
  };

  const handleBrowserNotification = (args: BrowserNotificationEvent) => {
    if (currentAgent?.id === args.notification.agent_id) {
      sendBrowserNotification(
        args.notification.title,
        args.notification.body,
        args.notification.deep_link_url
      );
    }
  };

  useEffect(() => {
    addEventHandler(
      "conversation_unread_count_changed",
      handleConversationUnreadCountChanged
    );

    addEventHandler("browser_notification", handleBrowserNotification);

    return () => {
      removeEventHandler(
        "conversation_unread_count_changed",
        handleConversationUnreadCountChanged
      );
      removeEventHandler("browser_notification", handleBrowserNotification);
    };
  }, [addEventHandler, removeEventHandler]);

  useEffect(() => {
    if (Object.keys(conversationUnreadCountChanged).length === 0) {
      return;
    }

    const unreadCountChanges = conversationUnreadCountChanged.unread_count;
    const newUnreadCounts = unreadCounts.filter(
      (uc) =>
        uc.merchant_id !==
        conversationUnreadCountChanged.unread_count.merchant_id
    );

    const newUnreadCount = {
      merchant_id: conversationUnreadCountChanged.unread_count.merchant_id,
      shared: unreadCountChanges.shared,
      personal: unreadCountChanges.personal[currentAgent!.id.toString()] || 0,
      unassigned: unreadCountChanges.unassigned,
      teams:
        unreadCountChanges.teams &&
        Object.keys(unreadCountChanges.teams).reduce(
          (changedTeams, currentTeamId) => {
            const teamUnreadCount = unreadCountChanges.teams![currentTeamId];

            changedTeams[currentTeamId] = {
              shared: teamUnreadCount.shared,
              personal:
                teamUnreadCount.personal[currentAgent!.id.toString()] || 0,
              unassigned: teamUnreadCount.unassigned,
            };

            return changedTeams;
          },
          {} as {
            [key: string]: {
              personal: number;
              shared: number;
              unassigned: number;
            };
          }
        ),
    };

    dispatch(setUnreadCounts([...newUnreadCounts, newUnreadCount]));
  }, [conversationUnreadCountChanged]);

  return (
    <>
      <PageBanner />
      {!isBaseSize ? (
        <Flex h="100%" maxHeight="100%">
          <HStack
            flexGrow={0}
            flexBasis="1px"
            flexShrink={0}
            h="100%"
            minHeight="100%"
            spacing={0}
          >
            <MerchantSwitch />
            <NavigationBar />
          </HStack>
          <Flex
            flexGrow={1}
            flexBasis="1px"
            flexShrink={0}
            h="100%"
            minWidth={0}
          >
            {children}
          </Flex>
        </Flex>
      ) : (
        <Flex h="100%" w="100%" maxHeight="100%" direction="column">
          <Flex
            w="100%"
            flexDirection="column"
            flexGrow={1}
            flexBasis="1px"
            flexShrink={0}
            overflow="hidden"
          >
            {children}
          </Flex>
          <Box w="100%" flexGrow={0} flexBasis="1px" flexShrink={0}>
            <NavigationBar />
          </Box>
        </Flex>
      )}
    </>
  );
};

export default PrivatePageWrapper;
