import { Text } from "@chakra-ui/react";
import useMarkFramePaint from "hooks/use-mark-frame-paint";
import React from "react";
import { useAppSelector } from "redux/hooks";

interface SubjectPreviewProps {}

const SubjectPreview: React.FC<SubjectPreviewProps> = () => {
  const {
    messageInput: { subject },
  } = useAppSelector((state) => state.conversations);

  return subject ? (
    <Text fontWeight="bold" p={2}>
      {`Subject: ${subject}`}
    </Text>
  ) : null;
};

export default SubjectPreview;
