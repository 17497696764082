import { InfoIcon } from "@chakra-ui/icons";
import { HStack, Icon, Text, Tooltip, useColorMode } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { SegmentedMessage } from "sms-segments-calculator";
import EncodedChar from "sms-segments-calculator/dist/libs/EncodedChar";
import Segment from "sms-segments-calculator/dist/libs/Segment";

interface SmsMaxLengthProps {
  text: string;
  setIsLimitExceeded: React.Dispatch<React.SetStateAction<boolean>>;
  setIsApproachingLimit: React.Dispatch<React.SetStateAction<boolean>>;
}

const SEGMENT_MAX_EXCEEDED_TEXT =
  "Message exceeds the maximum number of segments allowed for SMS";

export const MAX_SMS_SEGMENTS = 10;

export const getSegmentsBreakdown = (text: string) => {
  const segmentedMessage = new SegmentedMessage(text, "auto", true);

  const hasExceeded = segmentedMessage.segments.length > MAX_SMS_SEGMENTS;
  const isApproaching =
    MAX_SMS_SEGMENTS - segmentedMessage.segments.length <= 2;

  return {
    hasExceeded,
    isApproaching,
    segments: segmentedMessage.segments,
  };
};

const SmsMaxLength: React.FC<SmsMaxLengthProps> = ({
  text,
  setIsLimitExceeded,
  setIsApproachingLimit,
}: SmsMaxLengthProps) => {
  const { colorMode } = useColorMode();

  const [currentSegmentCount, setCurrentSegmentCount] = useState<number>(
    getSegmentsBreakdown(text).segments.length
  );
  const [isSegmentMaxExceeded, setIsSegmentMaxExceeded] = useState<boolean>(
    getSegmentsBreakdown(text).hasExceeded
  );

  const [isApproachingSegmentMaxLimit, setIsApproachingSegmentMaxLimit] =
    useState<boolean>(getSegmentsBreakdown(text).isApproaching);

  useEffect(() => {
    const { hasExceeded, isApproaching, segments } = getSegmentsBreakdown(text);

    setIsApproachingSegmentMaxLimit(isApproaching);
    setIsSegmentMaxExceeded(hasExceeded);

    if (hasExceeded || isApproaching) {
      setCurrentSegmentCount(segments.length);
    }
  }, [text]);

  useEffect(() => {
    setIsLimitExceeded(isSegmentMaxExceeded);
  }, [isSegmentMaxExceeded]);

  useEffect(() => {
    setIsApproachingLimit(isApproachingSegmentMaxLimit);
  }, [isApproachingSegmentMaxLimit]);

  return !isApproachingSegmentMaxLimit ? null : (
    <HStack
      w="100%"
      h="100%"
      wrap="wrap"
      pl={16}
      pr={8}
      position="absolute"
      alignItems="center"
      justifyContent="center"
    >
      <Text
        alignSelf="center"
        color={`${isSegmentMaxExceeded ? "red" : "orange"}.${
          colorMode === "dark" ? "200" : "500"
        }`}
        fontWeight="bold"
      >
        {currentSegmentCount}/10 Segments
      </Text>
      <Tooltip
        display="flex"
        width="300px"
        borderRadius="lg"
        label="SMS standards have barely changed since the days of the brickphone. Messages are still sent in 140 byte chunks known as message segments. Most SMS providers can send only a limited amount of segments so that modern phones could interpret those as one continuous message."
      >
        <Icon
          as={InfoIcon}
          color={`${colorMode === "dark" ? "red.200" : "red.500"}`}
        />
      </Tooltip>
      {isSegmentMaxExceeded ? (
        <Text
          alignSelf="center"
          color={colorMode === "dark" ? "red.200" : "red.500"}
          fontSize="sm"
        >
          {SEGMENT_MAX_EXCEEDED_TEXT}
        </Text>
      ) : null}
    </HStack>
  );
};

export default SmsMaxLength;
