import { Image } from "@chakra-ui/react";
import { SUPPORTED_MEDIA_TYPES } from "components/shared/FilePreview";
import React, { memo, useMemo } from "react";
import { MessageInputAttachment } from "redux/features/conversations";
import AttachmentWrapper from "./AttachmentWrapper";

interface AttachmentProps {
  attachment: MessageInputAttachment;
  size: "sm" | "md";
  onAttachmentRemove?: () => void;
  onOpenGallery: () => void;
}

const Attachment = ({
  attachment,
  size,
  onOpenGallery,
  onAttachmentRemove,
}: AttachmentProps) => {
  if (!SUPPORTED_MEDIA_TYPES.includes(attachment.type)) {
    return null;
  }

  const boxSize = useMemo(() => {
    if (size === "sm") {
      return "2rem";
    }

    return "4rem";
  }, [size]);

  const attachmentComponent = useMemo(() => {
    if (attachment.type.includes("image")) {
      return (
        <Image
          objectFit="cover"
          src={attachment.url}
          alt="Attachment"
          height={boxSize}
          width={boxSize}
          minHeight={boxSize}
          minWidth={boxSize}
          borderRadius="lg"
          cursor="pointer"
          onClick={onOpenGallery}
        />
      );
    }

    if (attachment.type.includes("video")) {
      return (
        <video
          src={attachment.url}
          controls={false}
          onClick={onOpenGallery}
          style={{
            height: boxSize,
            width: boxSize,
            minHeight: boxSize,
            minWidth: boxSize,
            cursor: "pointer",
            borderRadius: "0.5rem",
          }}
        />
      );
    }

    if (attachment.type.includes("audio")) {
      return (
        <audio
          src={attachment.url}
          controls={false}
          onClick={onOpenGallery}
          style={{
            height: boxSize,
            width: boxSize,
            minHeight: boxSize,
            minWidth: boxSize,
            cursor: "pointer",
            borderRadius: "0.5rem",
          }}
        >
          Your browser does not support the
          <code>audio</code> element.
        </audio>
      );
    }

    if (attachment.type.includes("pdf")) {
      return (
        <a
          href={attachment.url}
          target="_blank"
          rel="noopener noreferrer"
          download={true}
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          PDF Attachment
        </a>
      );
    }
  }, [attachment.type, attachment.url]);

  return (
    <AttachmentWrapper onRemove={onAttachmentRemove}>
      {attachmentComponent}
    </AttachmentWrapper>
  );
};

export default memo(Attachment);
