import { HStack } from "@chakra-ui/react";
import React, { useMemo } from "react";
import { isMobileApp } from "util/methods";

interface FooterProps {
  children: React.ReactNode;
}

const Footer: React.FC<FooterProps> = ({ children }) => {
  const isMobileApplication = useMemo(() => isMobileApp(), []);

  return (
    <HStack
      w="100%"
      px={isMobileApplication ? 4 : 2}
      py={1}
      pb={isMobileApplication ? 6 : 0}
      position="relative"
    >
      {children}
    </HStack>
  );
};

export default Footer;
