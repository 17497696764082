import { HStack, Text, useColorMode } from "@chakra-ui/react";
import React, { memo, useEffect, useState } from "react";

interface MaxLengthWarningProps {
  max: number;
  currentLength: number;
  setIsLimitExceeded: React.Dispatch<React.SetStateAction<boolean>>;
  setIsApproachingLimit: React.Dispatch<React.SetStateAction<boolean>>;
}

const isApproachingTextLimit = (currentLength: number, max: number) => {
  return currentLength > max - max * 0.2;
};

const isExceedingTextLimit = (currentLength: number, max: number) => {
  return currentLength > max;
};

const MaxLengthWarning: React.FC<MaxLengthWarningProps> = ({
  max,
  currentLength,
  setIsLimitExceeded,
  setIsApproachingLimit,
}) => {
  const { colorMode } = useColorMode();

  const [isMaxExceeded, setIsMaxExceeded] = useState<boolean>(
    isExceedingTextLimit(currentLength, max)
  );

  const [isApproachingMaxLimit, setIsApproachingMaxLimit] = useState<boolean>(
    isApproachingTextLimit(currentLength, max)
  );

  useEffect(() => {
    setIsLimitExceeded(isMaxExceeded);
  }, [isMaxExceeded]);

  useEffect(() => {
    setIsApproachingLimit(isApproachingMaxLimit);
  }, [isApproachingMaxLimit]);

  useEffect(() => {
    const hasExceeded = isExceedingTextLimit(currentLength, max);
    const isApproaching = isApproachingTextLimit(currentLength, max);

    setIsApproachingMaxLimit(isApproaching);
    setIsMaxExceeded(hasExceeded);
  }, [currentLength]);

  return !isApproachingMaxLimit ? null : (
    <HStack
      position="absolute"
      left="50%"
      top="50%"
      transform="translate(-50%, -50%)"
      zIndex={0}
    >
      <Text
        alignSelf="center"
        color={`${isMaxExceeded ? "red" : "orange"}.${
          colorMode === "dark" ? "200" : "500"
        }`}
        fontWeight="bold"
      >
        Please shorten text {currentLength}/{max}
      </Text>
    </HStack>
  );
};

export default memo(MaxLengthWarning);
