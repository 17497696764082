import { Icon as ChakraIcon } from "@chakra-ui/react";
import Tag from "components/tags";
import React from "react";
import { ReactSVG } from "react-svg";
import { OptionTypes } from ".";

interface DropdownOptionLabelProps {
  option: OptionTypes;
  isTag?: boolean;
  tagFontSize?: string;
  labelMarginL?: number;
}

const DropdownOptionLabel = ({
  option,
  isTag,
  tagFontSize,
  labelMarginL,
}: DropdownOptionLabelProps) => {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      {option.icon && (
        <ChakraIcon
          as={ReactSVG}
          src={option.icon}
          __css={{ svg: { height: "1rem", width: "1rem" } }}
        />
      )}
      {isTag ? (
        <>
          <Tag
            fontSize={tagFontSize}
            label={option.label}
            color={option.color}
          />
        </>
      ) : (
        <>
          <span style={{ marginLeft: labelMarginL || 5 }}>{option.label}</span>
        </>
      )}
    </div>
  );
};

export default DropdownOptionLabel;
