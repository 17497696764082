import {
  Box,
  Button,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useColorMode,
} from "@chakra-ui/react";
import { ReactComponent as TemplatesButtonIcon } from "assets/icons/templates-2.svg";
import React, { memo } from "react";
import { useAppSelector } from "redux/hooks";
import TemplatesPopover from "../templates-popover";

interface TemplatesShortcutProps {
  isDisabled?: boolean;
  invisibleTrigger?: boolean;
}

const TemplatesShortcut = ({
  isDisabled = false,
  invisibleTrigger = false,
}: TemplatesShortcutProps) => {
  const { colorMode } = useColorMode();
  const { colorScheme } = useAppSelector((state) => state.theme);

  return (
    <Popover
      closeOnBlur={true}
      placement={invisibleTrigger ? "top-start" : "top"}
      flip={false}
    >
      {({ isOpen, onClose }) => (
        <>
          <Portal>
            <PopoverContent>
              <TemplatesPopover
                isOpen={isOpen}
                id="templates-popover"
                onClose={onClose}
              />
            </PopoverContent>
          </Portal>
          <PopoverTrigger>
            {invisibleTrigger ? (
              <Button
                variant="unstyled"
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                borderRadius="xl"
              />
            ) : (
              <Button
                data-testid="select-template"
                variant="ghost"
                size="xs"
                colorScheme={colorScheme}
                p={0}
                isDisabled={isDisabled}
              >
                <Icon
                  as={TemplatesButtonIcon}
                  width="1rem"
                  height="1rem"
                  __css={{
                    path: {
                      fill:
                        colorMode === "light"
                          ? `${colorScheme}.500`
                          : `${colorScheme}.200`,
                    },
                  }}
                />
              </Button>
            )}
          </PopoverTrigger>
        </>
      )}
    </Popover>
  );
};

export default memo(TemplatesShortcut);
