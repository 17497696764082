import {
  Box,
  Button,
  Flex,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useColorMode,
} from "@chakra-ui/react";
import { ReactComponent as MailIcon } from "assets/icons/Mail-ds.svg";
import { ReactComponent as Bullhorn } from "assets/icons/bullhorn-regular.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar-2.svg";
import { ReactComponent as CreditCard } from "assets/icons/credit-card-2.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/fi-rr-settings.svg";
import { ReactComponent as StarIcon } from "assets/icons/fi-rr-star-2.svg";
import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { FaChartArea } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import { useAppSelector } from "redux/hooks";

interface DesktopNavButtonProps {
  icon: React.ReactNode;
  name: string;
  link: string;
  isDisabled?: boolean;
}

const DesktopNavButton = ({
  icon,
  name,
  link,
  isDisabled,
}: DesktopNavButtonProps) => {
  const { colorScheme } = useAppSelector((state) => state.theme);
  const { colorMode } = useColorMode();
  const location = useLocation();
  const isLinkActive = location.pathname.includes(link);

  return (
    <Popover placement="right" trigger="hover">
      <PopoverTrigger>
        <Button
          aria-label={`${name}-navigation-button`}
          isDisabled={isDisabled}
          variant={isLinkActive ? "solid" : "unstyled"}
          colorScheme={colorScheme}
          isActive={isLinkActive}
          borderRadius="lg"
          width="3.5rem"
          height="3.5rem"
          display="flex"
          m={2}
          _active={{
            bgColor: `${colorScheme}.500`,
          }}
          _hover={{
            bgColor: isLinkActive ? `${colorScheme}.600` : `${colorScheme}.600`,
          }}
        >
          <NavLink
            to={link}
            style={{
              padding: "1rem",
            }}
          >
            {icon}
          </NavLink>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          border="none"
          width="fit-content"
          bgColor={
            colorMode === "dark" ? `${colorScheme}.600` : `${colorScheme}.500`
          }
        >
          <PopoverArrow
            border="none"
            boxShadow="none"
            bgColor={
              colorMode === "dark" ? `${colorScheme}.600` : `${colorScheme}.500`
            }
          />
          <PopoverBody color="white">{name}</PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};

export const InboxLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);
  const { unreadCounts } = useAppSelector((state) => state.agents);
  const unreadCount = unreadCounts.find((uc) => uc.merchant_id === merchant.id);
  const { colorMode } = useColorMode();

  return (
    <DesktopNavButton
      icon={
        <Flex position="relative" alignItems="center" justifyContent="center">
          <Icon
            as={MailIcon}
            width="2.5rem"
            height="2.5rem"
            __css={{
              path: {
                fill: "white",
              },
              rect: {
                fill: "transparent",
              },
            }}
          />
          {unreadCount?.personal || unreadCount?.shared ? (
            <Box
              position="absolute"
              top="-0.75rem"
              right="-0.75rem"
              zIndex="1"
              borderRadius="full"
              height="1rem"
              width="1rem"
              bgColor={colorMode === "dark" ? "red.200" : "red.500"}
            />
          ) : null}
        </Flex>
      }
      name="Inbox"
      link={`/${merchant.id}/inbox`}
    />
  );
};

export const ContactLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <DesktopNavButton
      icon={
        <Icon
          as={AiOutlineUser}
          width="2rem"
          height="2rem"
          __css={{
            path: {
              fill: "white",
            },
            rect: {
              fill: "transparent",
            },
          }}
        />
      }
      name="Contacts"
      link={`/${merchant.id}/contacts`}
    />
  );
};

export const CampaignsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <DesktopNavButton
      icon={
        <Flex position="relative">
          <Icon
            as={Bullhorn}
            width="2rem"
            height="2rem"
            __css={{
              path: {
                fill: "white",
              },
              rect: {
                fill: "transparent",
              },
            }}
          />
        </Flex>
      }
      name="Campaigns"
      link={`/${merchant.id}/campaigns`}
    />
  );
};

export const CalendarLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <DesktopNavButton
      icon={
        <Icon
          as={CalendarIcon}
          width="2rem"
          height="2rem"
          __css={{
            path: {
              fill: "white",
            },
            rect: {
              fill: "transparent",
            },
          }}
        />
      }
      name="Calendar"
      link={`/${merchant.id}/calendar`}
    />
  );
};

export const PaymentsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <DesktopNavButton
      icon={
        <Icon
          as={CreditCard}
          width="2rem"
          height="2rem"
          __css={{
            path: {
              fill: "white",
            },
            rect: {
              fill: "transparent",
            },
          }}
        />
      }
      name="Payments"
      link={`/${merchant.id}/payments`}
    />
  );
};

export const ReviewsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <DesktopNavButton
      icon={
        <Icon
          as={StarIcon}
          width="2rem"
          height="2rem"
          __css={{
            path: {
              fill: "white",
            },
            rect: {
              fill: "transparent",
            },
          }}
        />
      }
      name="Reviews"
      link={`/${merchant.id}/reviews`}
    />
  );
};

export const AnalyticsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <DesktopNavButton
      icon={
        <Icon
          as={FaChartArea}
          width="2rem"
          height="2rem"
          __css={{
            path: {
              fill: "white",
            },
            rect: {
              fill: "transparent",
            },
          }}
        />
      }
      name="Analytics"
      link={`/${merchant.id}/analytics`}
    />
  );
};

export const SettingsLink = () => {
  const { merchant } = useAppSelector((state) => state.merchant);

  return (
    <DesktopNavButton
      icon={
        <Icon
          as={SettingsIcon}
          width="2rem"
          height="2rem"
          __css={{
            path: {
              fill: "white",
            },
            rect: {
              fill: "transparent",
            },
          }}
        />
      }
      name="Settings"
      link={`/${merchant.id}/settings`}
    />
  );
};

export default DesktopNavButton;
