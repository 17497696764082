import {
  Badge,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorMode,
} from "@chakra-ui/react";
import { ReactComponent as AllConversationsIcon } from "assets/icons/all-conversations.svg";
import { ReactComponent as PersonalConversationsIcon } from "assets/icons/personal-conversations.svg";
import { ReactComponent as UnassignedConversationsIcon } from "assets/icons/unassigned-conversations.svg";
import { UnreadCountDTO } from "entities/dto/UnreadCountDTO";
import useConversationsStore from "hooks/use-conversations-store";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import {
  ConversationTab,
  clearSelectedConversations,
  fetchConversations,
  setActiveConversationTab,
  setSearchText,
  setSelectedInbox,
} from "redux/features/conversations";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { canManageTeamInbox } from "util/permissions";
import ConversationSnippetList from "../shared/conversation-snippet-list";

const getActiveTabIndex = (
  tabName: ConversationTab | undefined
): number | undefined => {
  switch (tabName) {
    case ConversationTab.Personal:
      return 1;
    case ConversationTab.Team:
      return 0;
    case ConversationTab.Unassigned:
      return 2;
    default:
      return undefined;
  }
};

const getTabName = (index: number): ConversationTab => {
  switch (index) {
    case 0:
      return ConversationTab.Team;
    case 1:
      return ConversationTab.Personal;
    case 2:
      return ConversationTab.Unassigned;
    default:
      throw new Error(`Invalid tab (index: ${index}) has been chosen`);
  }
};

interface MobileTabsAreaProps {}

const getUnreadCount = (
  teamId: string | null,
  tab: string,
  unreadCount?: UnreadCountDTO
): number => {
  if (!unreadCount) {
    return 0;
  }

  if (teamId === null) {
    return unreadCount[tab as "personal" | "shared" | "unassigned"];
  }

  if (!unreadCount.teams) {
    return 0;
  }

  if (!unreadCount.teams[teamId]) {
    return 0;
  }

  return unreadCount.teams[teamId][
    tab as "personal" | "shared" | "unassigned"
  ]!;
};

const MobileTabsArea = (_props: MobileTabsAreaProps) => {
  const dispatch = useAppDispatch();
  const { getConversations, getConversation } = useConversationsStore();
  const { merchant } = useAppSelector((state) => state.merchant);
  const { agents, currentAgent, unreadCounts } = useAppSelector(
    (state) => state.agents
  );
  const unreadCount = unreadCounts.find((uc) => uc.merchant_id === merchant.id);
  const { colorMode } = useColorMode();
  const [currentSearchParameters, setSearchParams] = useSearchParams();
  const {
    activeTab,
    searchText,
    filterAgents,
    filterChannels,
    activeConversationId: conversationId,
    filterCustomerTagIds,
    isOpenOrClosed,
    selectedConversationIds,
    selectedInbox,
  } = useAppSelector((state) => state.conversations);
  const { activeConversationId } = useAppSelector(
    (state) => state.conversations
  );
  const { colorScheme } = useAppSelector((state) => state.theme);

  useEffect(() => {
    return () => {
      dispatch(setSearchText(""));
      dispatch(setSelectedInbox(null));
    };
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(currentSearchParameters);
    const tab = queryParams.get("tab") as ConversationTab | null;

    if (!tab) {
      const isSingleAgent = agents.length === 1;
      const isManager = canManageTeamInbox(merchant.id, currentAgent!);
      const shouldShowAllInOneTab = isManager && isSingleAgent;

      if (shouldShowAllInOneTab) {
        dispatch(setActiveConversationTab(ConversationTab.Team));
      } else {
        dispatch(setActiveConversationTab(ConversationTab.Personal));
      }
    } else if (tab !== activeTab) {
      dispatch(setActiveConversationTab(tab));
    }
  }, [
    agents,
    currentAgent,
    merchant,
    currentSearchParameters,
    activeConversationId,
  ]);

  useEffect(() => {
    const queryParams = new URLSearchParams(currentSearchParameters);
    const tab = queryParams.get("tab") as ConversationTab | null;

    if (activeTab && !tab) {
      setSearchParams({
        ...Object.fromEntries(currentSearchParameters.entries()),
        tab: activeTab,
      });
    }
  }, [activeTab]);

  useEffect(() => {
    if (activeTab) {
      dispatch(clearSelectedConversations());
      getConversations(0).then((res) => {
        const filterResult = res?.filter((conv) => conv.id === conversationId);

        if (filterResult?.length === 0 && conversationId) {
          getConversation(conversationId, currentAgent!.id);
        }
      });
    }
  }, [
    searchText,
    filterChannels,
    filterAgents,
    filterCustomerTagIds,
    isOpenOrClosed,
    activeTab,
    selectedInbox,
  ]);

  if (!activeTab) {
    return null;
  }

  const activeTabIndex = getActiveTabIndex(activeTab);

  if (agents.length > 1) {
    return (
      <Tabs
        colorScheme={colorScheme}
        display="flex"
        flexDirection="column"
        overflow="hidden"
        variant="line"
        height="100%"
        width="100%"
        minWidth="100%"
        alignItems="stretch"
        index={activeTabIndex}
        onChange={(ind: number) => {
          dispatch(fetchConversations());
          setSearchParams({
            ...Object.fromEntries(currentSearchParameters.entries()),
            tab: getTabName(ind),
          });
        }}
        isFitted={true}
      >
        <TabList display={selectedConversationIds.length ? "none" : "flex"}>
          <Tab
            display={
              canManageTeamInbox(merchant.id, currentAgent!) ? "flex" : "none"
            }
          >
            <Icon
              as={AllConversationsIcon}
              mr={1}
              width="1.35rem"
              height="1.35rem"
              __css={{
                path: {
                  fill: "none!important",
                  stroke:
                    activeTabIndex === 0
                      ? colorMode === "dark"
                        ? `${colorScheme}.200`
                        : `${colorScheme}.600`
                      : colorMode === "dark"
                      ? "gray.200"
                      : "gray.600",
                },
              }}
            />
            All{" "}
            {getUnreadCount(selectedInbox, "shared", unreadCount) ? (
              <Badge ml={1} colorScheme="red">
                {getUnreadCount(selectedInbox, "shared", unreadCount) > 100
                  ? "100+"
                  : getUnreadCount(selectedInbox, "shared", unreadCount)}
              </Badge>
            ) : null}
          </Tab>
          <Tab>
            <Icon
              as={PersonalConversationsIcon}
              mr={1}
              width="1.5rem"
              height="1.5rem"
              __css={{
                path: {
                  fill: "none!important",
                  stroke:
                    activeTabIndex === 1
                      ? colorMode === "dark"
                        ? `${colorScheme}.200`
                        : `${colorScheme}.600`
                      : colorMode === "dark"
                      ? "gray.200"
                      : "gray.600",
                },
              }}
            />
            Personal{" "}
            {getUnreadCount(selectedInbox, "personal", unreadCount) ? (
              <Badge ml={1} colorScheme="red">
                {getUnreadCount(selectedInbox, "personal", unreadCount) > 100
                  ? "100+"
                  : getUnreadCount(selectedInbox, "personal", unreadCount)}
              </Badge>
            ) : null}
          </Tab>
          <Tab>
            <Icon
              as={UnassignedConversationsIcon}
              mr={1}
              width="1.5rem"
              height="1.5rem"
              __css={{
                path: {
                  fill: "none!important",
                  stroke:
                    activeTabIndex === 2
                      ? colorMode === "dark"
                        ? `${colorScheme}.200`
                        : `${colorScheme}.600`
                      : colorMode === "dark"
                      ? "gray.200"
                      : "gray.600",
                },
              }}
            />
            Unassigned
            {getUnreadCount(selectedInbox, "unassigned", unreadCount) ? (
              <Badge ml={1} colorScheme="red">
                {getUnreadCount(selectedInbox, "unassigned", unreadCount) > 100
                  ? "100+"
                  : getUnreadCount(selectedInbox, "unassigned", unreadCount)}
              </Badge>
            ) : null}
          </Tab>
        </TabList>
        <TabPanels h="100%" display="flex" overflow="hidden">
          <TabPanel display="flex" w="100%" h="100%" overflowY="hidden" p={0}>
            <ConversationSnippetList />
          </TabPanel>
          <TabPanel display="flex" w="100%" h="100%" overflowY="hidden" p={0}>
            <ConversationSnippetList />
          </TabPanel>
          <TabPanel display="flex" w="100%" h="100%" overflowY="hidden" p={0}>
            <ConversationSnippetList />
          </TabPanel>
        </TabPanels>
      </Tabs>
    );
  }

  return <ConversationSnippetList />;
};

export default MobileTabsArea;
